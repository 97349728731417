import { defineStore } from "pinia";
import { useMemberStore } from "./MemberStore";
import { useAuthStore } from "./AuthStore";
import { useModalStore } from "./ModalStore";
import { useEventStore } from "./EventStore";
import { findDifferences } from "../helpers/functions";
// import { useRoute, useRouter } from 'vue-router';
import axios from "axios";

export const useLocationStore = defineStore("location", {
  state: () => ({
    locations: [],
    selectedLocation: null,
    isModalVisible: false, // Modal visibility state
    modalTitle: "My Modal", // Modal title
    memberMap: new Map(),
    // selectedLocation: {
    //   members: [],
    //   agenda: null
    // }
  }),
  getters: {
    locationCount(state) {
      return state.locations ? state.locations.length : 0;
    },
    locationMemberCount(state) {
      return state.selectedLocation?.members?.length;
    },
    filteredMembers(state) {
      const memberStore = useMemberStore();
      const eventStore = useEventStore();
      if (
        memberStore.search !== null &&
        memberStore.search !== undefined &&
        state.selectedLocation?.members?.length > 0
      ) {
        const searchText = memberStore.search.toLowerCase();
        state.selectedLocation.members.sort((a, b) =>
          a.firstname.localeCompare(b.firstname)
        );
        return state.selectedLocation.members.filter((member) => {
          const fullname = member.firstname + " " + member.lastname;
          if (
            eventStore.eventInfo &&
            member.usertype !== "_intro" &&
            (memberStore.selectedMembership === null ||
              memberStore.selectedMembership === undefined)
          ) {
            // If selectedMembership is null or undefined, don't apply membership filter
            return fullname.toLowerCase().includes(searchText);
          } else if (eventStore.eventInfo && member.usertype !== "_intro") {
            const selectedMembership =
              memberStore.selectedMembership.toLowerCase();
            return (
              fullname.toLowerCase().includes(searchText) &&
              (selectedMembership === "all" ||
                member.usertype.toLowerCase() === selectedMembership)
            );
          }
        });
      }
    },
    filteredDuoMembers(state) {
      const memberStore = useMemberStore();

      state.selectedLocation.members.sort((a, b) =>
        a.firstname.localeCompare(b.firstname)
      );
      return state.selectedLocation.members.filter((member) => {
        if (
          (member.usertype == "duo" && !member.duo) ||
          (member.usertype == "duo" &&
            member.id == memberStore.selectedMember?.duo)
        ) {
          // If selectedMembership is null or undefined, don't apply membership filter
          return true;
        }
      });
    },
    isCurrentLocationAltered() {
      // if (!this.selectedLocation) return false;
      // const originalLocation = this.locations.find(location => location.id === this.selectedLocation.id);
      // if (!originalLocation) return false;
      // const differences = findDifferences(this.selectedLocation, originalLocation);
      // if (differences.length > 0) {
      //   console.log('Differences between current and original location:', differences);
      //   return true;
      // }
      // return false;
    },
    isCurrentLocationAgendaAltered() {
      // if (!this.selectedLocation) return false;
      // const originalLocation = this.locations.find(location => location.id === this.selectedLocation.id);
      // if (!originalLocation) return false;
      // const differences = findDifferences(this.selectedLocation.agenda, originalLocation.agenda);
      // if (differences.length > 0) {
      //   console.log('Differences in agenda:', differences);
      //   return true;
      // }
      // return false;
    },
  },
  actions: {
    async fetchLocations() {
      console.log("Fetch locations");
      try {
        this.isLoading = true;
        // Load locations based on which the admin user can access.
        // Get Members inside the locations because why not?
        const response = await axios.post(
          `${import.meta.env.VITE_API_URL}load-location.php?`,
          { location: 1 }
        );
        //this.locations = response.data;

        if (Array.isArray(response.data)) {
          this.locations = response.data;
          console.log(this.locations);
        } else {
          console.error(
            "Expected an array from the server but received:",
            response.data
          );
        }

        this.error = null;
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    async pagedMembers(limit, page) {
      const authStore = useAuthStore();
      const locationId = this.selectedLocation?.id;

      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}load-members.php`,
        {
          params: {
            limit: limit,
            page: page,
            location_id: locationId,
            token: authStore.user.token,
          },
        }
      );

      return response.data;
    },
    async fetchLocationRecurring() {
      const authStore = useAuthStore();
      const locationId = this.selectedLocation?.id;

      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}load-recurring.php`,
        {
          params: {
            location: locationId,
            token: authStore.user.token,
          },
        }
      );
      console.log(response.data);


      this.selectedLocation.recurring = response.data;
    },
    async fetchLocationMembers() {
      const memberStore = useMemberStore();
      const authStore = useAuthStore();
      console.log("locationStore: fetchLocationMembers");

      try {
        this.isLoading = true;
        // Request only the essential fields initially
        let allMembers = [];
        let page = 1;
        let limit = 500;

        const initialResponse = await this.pagedMembers(limit, page);

        if (initialResponse.members) {
          allMembers = allMembers.concat(initialResponse.members);

          if (initialResponse.pages > 1) {
            page++; // Move to the next page

            while (page <= initialResponse.pages) {
              const pagedMembersResponse = await this.pagedMembers(limit, page);

              if (pagedMembersResponse.members) {
                allMembers = allMembers.concat(pagedMembersResponse.members);
                page++; // Move to the next page
              } else {
                break; // Break the loop if no more members are found
              }
            }
          }
        }

        // Map over the allMembers array and add a fullname property to each member
        const enrichedMembers = allMembers.map((member) => ({
          ...member,
          fullname: member.firstname + " " + member.lastname,
        }));

        this.selectedLocation.members = enrichedMembers;
        memberStore.members = enrichedMembers;

        this.addMembersToLocation(this.selectedLocation.members);
        this.error = null;
      } catch (error) {
        this.error = error.message;
        if (error.response?.status === 401) {
          // Specifieke afhandeling voor 401 Unauthorized
          console.error("Authentication error: Unauthorized (401)");
          authStore.logout();
        }
      } finally {
        this.isLoading = false;
      }
    },

    // async fetchLocationMembers() {
    //   console.log('Fetch members for location');

    //   const locationId = this.selectedLocation.id;
    //   try {
    //     this.isLoading = true;
    //     const response = await axios.get(`${import.meta.env.VITE_API_URL}load-members.php`, {
    //       params: {
    //           location_id: locationId
    //       }
    //     });
    //     this.selectedLocation.members = response.data;
    //     console.log(response);
    //     this.addMembersToLocation(this.selectedLocation.members);
    //     this.error = null;
    //   } catch(error) {
    //     this.error = error.message;
    //   } finally {
    //     this.loading = false;
    //   }
    // },
    fetchLocationMember(id) {
      let matchingMember = null;
      if (typeof id === "number") {
        // console.log('Looking for member with ID (number):', id);
        // Check if members array is correctly populated
        // console.log('Members array:', this.selectedLocation?.members);

        // Use 'find' to get the first matching member
        matchingMember = this.selectedLocation?.members?.find(
          (member) =>
            // Convert both to the same type (e.g., both to numbers) if there's a type mismatch
            Number(member.id) === id
        );

        // console.log('Found matching member:', matchingMember);
      } else {
        matchingMember = { fullname: id }; // If it's not a number, assume it's a full name
      }

      return matchingMember;
    },
    // Why is this not a getter?
    fetchLocationMembersByIDs(ids) {
      // console.log("Fetching members by IDs:", ids);
      // console.log("Type of ids:", typeof ids);

      let processedIds;
      if (typeof ids === "string") {
        try {
          // Attempt to parse the string as JSON
          processedIds = JSON.parse(ids);
        } catch (e) {
          // If JSON parsing fails, split by comma
          processedIds = ids.split(",").map((id) => id.trim());
        }
      } else if (Array.isArray(ids)) {
        processedIds = ids;
      } else {
        console.error("Invalid input for fetchLocationMembersByIDs:", ids);
        return [];
      }

      // console.log("Processed IDs:", processedIds);
      // console.log("Is array after processing:", Array.isArray(processedIds));

      if (!Array.isArray(processedIds)) {
        console.error("Failed to process IDs into an array:", ids);
        return [];
      }

      if (!this.selectedLocation?.members) {
        console.log("No members in selected location");
        return [];
      }

      const idSet = new Set(processedIds.map((id) => id.toString()));

      const matchingMembers = this.selectedLocation.members.filter((member) =>
        idSet.has(member.id.toString())
      );

      // console.log("Matching members:", matchingMembers);
      return matchingMembers;
    },

    // async fetchLocationById(locId) {
    //   locId = Number(locId);
    //   try {
    //     this.isLoading = true;
    //     const response = await axios.get('http://localhost:5173/src/assets/locations.json');
    //     const results = response.data;
    //     // Filter the locations array based on the location_id
    //     const locationResult = results.filter(result => Number(result.id) === locId);
    //     console.log(locationResult);
    //     this.locations = locationResult; // Update the location state with the filtered location
    //     this.error = null;
    //   } catch(error) {
    //     this.error = error.message;
    //   } finally {
    //     this.loading = false;
    //   }
    // },
    addLocation(location) {
      this.locations.push(location);
    },
    setCurrentLocation(id) {
      if (!id) {
        this.selectedLocation = null;
        return Promise.resolve(null);
      }
      
      // Find the location object with the matching id
      const location = Object.values(this.locations).find(
        (location) => location.id == id
      );
    
      if (location) {
        // Create a shallow copy of the selected location object
        this.selectedLocation = { ...location };
    
        // Return the promise from fetchLocationMembers
        return this.fetchLocationMembers().then(() => {
          console.log("Location members fetched");
          return this.selectedLocation; // Return the selected location
        });
      } else {
        return Promise.reject("Location not found");
      }
    },
    addMembersToLocation(members) {
      members.forEach((member) => {
        // console.log(member);
        this.locations.forEach((location) => {
          // location.id and member.location_id are not same type so don't use === or change first
          if (location.id == member.location_id) {
            if (!location.members) {
              location.members = []; // Initialize members array if it doesn't exist
            }
            return location.members.push(member);
            // Trigger a computed property update
            this.$patch({ selectedLocation: location });
          }
        });
      });
    },
    addMemberToLocation(member) {
      this.locations.forEach((location) => {
        if (location.id === member.location_id) {
          return location.members.push(member);
        }
      });
    },

    async updateLocationAgenda(openHours, force) {
      const modalStore = useModalStore();
      // const authStore = useAuthStore();

      try {
        console.log(
          "refetch: " +
            import.meta.env.VITE_API_URL +
            "update-agenda.php?location=" +
            this.selectedLocation.id +
            "&openhours=" +
            openHours
        );

        if (!force) {
          var addForce = "";
          const response = await axios.get(
            `${import.meta.env.VITE_API_URL}update-agenda.php?location=${
              this.selectedLocation.id
            }&openhours=${openHours}${addForce}`,
            {
              headers: {
                Authorization: `Bearer ${authStore.user.token}`,
                //   'X-Access-User': this.user.id
              },
            }
          );
          console.log(response);
          if (response.data.status == "notify") {
            modalStore.setModalVisible("notifyAgenda", true);

            this.selectedLocation.notify = response.data.notify;
          } else {
            modalStore.setModalVisible("notifyAgenda", false);
            this.locations[this.selectedLocation.id].openHours =
              JSON.parse(openHours);
            this.selectedLocation.notify = false;
          }
        } else if (force) {
          var addForce = "&force=true";
          const response = await axios.get(
            `${import.meta.env.VITE_API_URL}update-agenda.php?location=${
              this.selectedLocation.id
            }&openhours=${openHours}${addForce}`,
            {
              headers: {
                // Authorization: `Bearer ${authStore.user.token}`,
                //   'X-Access-User': this.user.id
              },
            }
          );
          this.locations[this.selectedLocation.id].openHours =
            JSON.parse(openHours);
          console.log(response);
          modalStore.setModalVisible("notifyAgenda", false);
          this.selectedLocation.notify = false;
        }
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
  },
  persist: true,
});
