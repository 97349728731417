import { defineStore } from 'pinia';
import { useModalStore } from './ModalStore'
import { useLocationStore } from './LocationStore'
import { useAuthStore } from './AuthStore'
import moment from 'moment';
import { computed } from 'vue'

import axios from 'axios';

export const useMemberStore = defineStore('member', {
  state: () => ({
    isLoading: false,
    error: null,
    members: [],
    isModalVisible: false, // Modal visibility state
    modalTitle: 'My Modal', // Modal title
    search: '',
    duoSearch : '',
    selectedMembership: 'all',
    newMember: {
      id : false,
      firstname: "",
      lastname: "",
      email: "",
      usertype: "",
      notify: false,
      phone: "",
      location: ""
    },
    
    kmt: {
      showDuoForm : false,
      memberInfo : {},
      duoInfo : {},
    },
    memberships: ['solo', 'flex', 'duo', 'kmt'],
    selectedMember: null,
    selectedMemberLoad: null,
  }),
  getters: {
    membersCount(state) {
      return state.members.length
    },
    // Filter items based on search text and selected category
    filteredMembers(state) {
      if (state.search !== null && state.search !== undefined) {
        const searchText = state.search.toLowerCase();
        return state.members.filter(member => {
          const fullname = member.firstname + ' ' + member.lastname;
          if (state.selectedMembership === null || state.selectedMembership === undefined) {
            // If selectedMembership is null or undefined, don't apply membership filter
            return fullname.toLowerCase().includes(searchText);
          } else {
            const selectedMembership = state.selectedMembership;
            return (
              fullname.toLowerCase().includes(searchText) &&
              (selectedMembership === 'all' || (member.usertype && member.usertype.toLowerCase() === selectedMembership.toLowerCase()))
            );
          }
        });
      }
    },
    filteredMemberTimeslotsComing(state) {
      // const authStore = useAuthStore();
      // if (!['flex', 'solo', 'duo'].includes(authStore.user.usertype)) {
      //   return [];  // Return an empty array if the usertype is not one of the specified types
      // }
      const currentDay = moment().startOf('day'); // Get the start of the current day

      // Check if selectedMember exists and is not an empty object
      if (!state.selectedMember || Object.keys(state.selectedMember).length === 0 || !state.selectedMember.timeslots) {
        return [];  // Return an empty array if selectedMember is not defined or is an empty object
      }

      return state.selectedMember.timeslots.filter(timeslot =>
        moment(timeslot.start).isSameOrAfter(currentDay)
      );
    },
    filteredMemberTimeslotsPast(state) {
      const currentDay = moment().startOf('day');
  
      // Check if selectedMember exists and is not an empty object
      if (!state.selectedMember || Object.keys(state.selectedMember).length === 0 || !state.selectedMember.timeslots) {
        return [];  // Return an empty array if selectedMember is not defined or is an empty object
      }
    
      return state.selectedMember.timeslots.filter(timeslot =>
        moment(timeslot.start).isBefore(currentDay)
      );
    },
    // Kopie van uit authStore.js aangepast
    // sortedTimeslots moet globaal worden en combine ook.
    // filteredTimeslots hierboven is dan dubbelop, want sortedTimeslots maakt al past/upcoming arrays.
    sortedTimeslots(state) {
      if (!state.selectedMember?.timeslots) {
        return { past: [], upcoming: [] };
      }
    
      // Function to get the week number and year
      const getWeekNumberAndYear = (date) => {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
        const weekNumber = Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
        return { weekNumber, year: date.getFullYear() };
      };
    
      const now = new Date();
      const currentWeekAndYear = getWeekNumberAndYear(now);
      const past = [];
      const upcoming = [];
    
      state.selectedMember.timeslots.forEach((timeslot) => {
        const date = new Date(timeslot.start);
        const weekAndYear = getWeekNumberAndYear(date);
    
        // Compare both week number and year
        if (weekAndYear.year < currentWeekAndYear.year || 
           (weekAndYear.year === currentWeekAndYear.year && weekAndYear.weekNumber < currentWeekAndYear.weekNumber)) {
          past.push(timeslot);
        } else {
          upcoming.push(timeslot);
        }
      });
    
      // Sort the arrays
      past.sort((a, b) => new Date(a.start) - new Date(b.start));
      upcoming.sort((a, b) => new Date(a.start) - new Date(b.start));
    
      return { past, upcoming };
    },
    // Kopie van uit authStore.js aangepast
    combinedWeeksAndTrainings(state) {
      // Access lastUpdate to create a dependency
      // console.log("Update tracker:", state.sortedTimeslotsUpdateTracker); // log the tracker to create a dependency

      let combinedArray = [];
      const authStore = useAuthStore();
      authStore.bookableWeeks.forEach((weekObject) => {
        // Create a new week object that is a copy of the original
        let newWeekObject = { ...weekObject };
        let matchingTrainings = state.sortedTimeslots.upcoming.filter(
          (training) => {
            return training.extendedProps.week == newWeekObject.week;
          }
        );
        if (matchingTrainings && matchingTrainings.length > 0) {
          // Set the training property on the new week object to an array of trainings
          newWeekObject.trainings = matchingTrainings;
        }

        combinedArray.push(newWeekObject);
      });

      return combinedArray;
    },
    /**
    This function checks if the selected member in the store has been updated by comparing its properties with
    the same properties of the members stored in the store. If the properties are different, then it returns true,
    otherwise it returns false.
    @param {object} state - The state object of the MemberStore.
    @returns {boolean} - Returns true if the selected member has been updated, false otherwise.
    */
    isSelectedMemberChanged(state) { 
      // deze werkt momenteel niet als je gelijk naar leden gaat en dan een member selecteert.
      let isSelectedMemberUpdated = false;
      const selectedMember = state.selectedMember;

      if (selectedMember) {
        //isSelectedMemberUpdated = true;
        const member =  state.selectedMemberLoad;
        if (member && JSON.stringify(selectedMember) !== JSON.stringify(member)) {
          isSelectedMemberUpdated = true;
        } 
      }

      return isSelectedMemberUpdated;
    },
    isKmtMemberValid(state) {
      let isKmtMemberValid = true;
      if((state.kmt.showDuoForm == false && (state.kmt.memberInfo.length == 0 || !state.kmt.memberInfo.firstname || state.kmt.memberInfo.firstname == '' || !state.kmt.memberInfo.lastname || state.kmt.memberInfo.lastname == '')) || (state.kmt.showDuoForm == true && (state.kmt.memberInfo.length == 0 || !state.kmt.memberInfo.firstname || state.kmt.memberInfo.firstname == '' || !state.kmt.memberInfo.lastname || state.kmt.memberInfo.lastname == '' || state.kmt.duoInfo.length == 0 || !state.kmt.duoInfo.firstname || state.kmt.duoInfo.firstname == '' || !state.kmt.duoInfo.lastname || state.kmt.duoInfo.lastname == ''))){
        isKmtMemberValid = false;
      }

      return isKmtMemberValid;
    },
    isNewMemberValid(state) {
      let isNewMemberValid = true;
      
      if(state.newMember.firstname == '' || state.newMember.lastname == '' || state.newMember.usertype == '' || state.newMember.location == '') {
        isNewMemberValid = false;
      }

      return isNewMemberValid;
    }
  },
  actions: {
    async fetchMembers() {
      console.log('Fetch members');
      const memberStore = useMemberStore();
      const authStore = useAuthStore();
      console.log('locationStore: fetchLocationMembers');
      const locationId = this.selectedLocation?.id;
      try {
        this.isLoading = true;
        // const response = await axios.get(import.meta.env.VITE_API_URL+'load-members.php?location_id=1');
        const response = await axios.get(`${import.meta.env.VITE_API_URL}load-members.php`, {
          params: {
              location_id: locationId,
              token: authStore.user.token,
          },
          // Enable gzip decompression
            decompress: true
        });


        this.members = response.data;
        console.log(response);
        const locationStore = useLocationStore();
        locationStore.addMembersToLocation(this.members);
        this.error = null;
      } catch(error) {
        this.error = error.message;
        
      if (error.response.status === 401) {
        // Specifieke afhandeling voor 401 Unauthorized
        console.error('Authentication error: Unauthorized (401)');
        authStore.logout();
      }
      } finally {
        this.loading = false;
      }
    },
    addMember(member) {
      
      const newMember = {
        id: Math.floor(Math.random() * 1000000),
        firstname: member.firstname,
        lastname: member.lastname,
        email: member.email,
        membership: member.membership,
        notify: false,
        phone: member.phone,
        location: member.location
      }
      return new Promise((resolve, reject) => {
        // Here, you would perform the logic to add the member to the store
        // For example, you could use an API call or update a Vuex store
        // Once the member is added, you can resolve the Promise with the new member object
        // If an error occurs, you can reject the Promise with the error
        try {
          this.members.push(newMember);
          console.log('Member added:', newMember);
          resolve(newMember);
        } catch (error) {
          console.error('Error adding member:', error);
          reject(error);
        }
      });
    },
    removeNoteFromMember(noteIndex,member) {
      if(confirm('Notitie verwijderen?')){
        this.selectedMember = member;
        const noteId = this.selectedMember.notes[noteIndex]?.id;
        this.selectedMember.notes?.splice(noteIndex, 1);
        this.selectedMember.removeNotes?.push(noteId);

        this.updateMember(this.selectedMember);
      }
      
    },
    sendPasswordResetEmail(email) {
      const modalStore = useModalStore();
      const authStore = useAuthStore();
      if(confirm('Weet je zeker dat je een wachtwoord reset e-mail wilt sturen naar ' + email + '?')){
        authStore.resetPassword(email);
        alert('Wachtwoord reset e-mail is verstuurd naar ' + email);
        modalStore.setModalVisible('selectMember', false);
        
      }
    },
    findMemberDataById(id) {
      return this.members.find(member => member.id === id);
    },
    async getMemberById(id) {
      const authStore = useAuthStore();
      try {
          //console.log('get Member by id ' + import.meta.env.VITE_API_URL+'load-user.php?user_id=' + id);
          const response = await axios.get(`${import.meta.env.VITE_API_URL}load-user.php?user_id=${id}&token=${authStore.user.token}`, { 
              headers: {
                'Authorization': `Bearer ${authStore.user.token}`,
              //   'X-Access-User': this.user.id
              }
          });
          
          this.isLoading = true;
          this.error = null;
          this.selectedMemberLoad = response.data;
          this.selectedMember = response.data;
          // console.log(this.selectedMember);
          const locationStore = useLocationStore();
          const memberIndex = locationStore.selectedLocation.members.findIndex(m => m.id == this.selectedMember.id);
          if (memberIndex !== -1) {
            locationStore.selectedLocation.members[memberIndex] = { ...this.selectedMember };
          }

      } catch(error) {
          this.error = error.message;
          if (error.response?.status === 401) {
            // Specifieke afhandeling voor 401 Unauthorized
            console.error('Authentication error: Unauthorized (401)');
            authStore.logout();
          }
      } finally {
          this.loading = false;
      }
    },
    async updateMember(member) {
      const locationStore = useLocationStore();
      const authStore = useAuthStore();
      console.log("Updating member: " + member)
      // In load members is het id en hier member_id???
      var params = {
        
        firstname: member.firstname,
        lastname: member.lastname,
        email: member.email,
        usertype: member.usertype,
        phone: member.phone,
        token : authStore.user.token,
        location_id : member.location,
      }
      if(member.id !== false){
        params.user_id = member.id;
      }
      if(member.hasOwnProperty('removeNotes')){
        params.removeNotes = member.removeNotes;
      }
      if(member.usertype == 'duo' && member?.duo !== null) {
        params.duo = member.duo;
      }
       // Controleer of member.inactive bestaat en waar is
      if (member.inactive) {
        params.inactive = true;
        // Als member.inactive_from bestaat, voeg het toe aan params
        if (member.hasOwnProperty('inactive_from')) {
          params.inactive_from = member.inactive_from;
        }

        // Als member.inactive_until bestaat, voeg het toe aan params
        if (member.hasOwnProperty('inactive_until')) {
          params.inactive_until = member.inactive_until;
        }
      }
      
      if(member.addNote){
        params.addNote = {
          date : moment().format('YYYY-MM-DD\THH:mm:ss'),
          timeslot : false,
          message : member.addNote
        };
      }
      if(member.addEventNote){
        params.addNote = member.addEventNote;
      }
      try {
        this.isLoading = true;

      
        console.log(params);
        const response = await axios.post(import.meta.env.VITE_API_URL+'update-user.php', params);

        if(member.id){
          locationStore.fetchLocationMembers();
        }
        console.log(response);
        this.error = null;
        // Update the main state with the updated member object
        this.members = this.members.map(m => {
          if (m.id === member.id) {
            member.notes = response.data.notes;
            member.addNote = '';
            member.addEventNote = '';
            return member;
          } else{
            if(member.usertype == 'duo' && member.inactive && m.id === member.duo) {
              m.alt_usertype = 'duo';
              m.alt_usertype_from = member.inactive_from;
              m.alt_usertype_until = member.inactive_until;
              m.alt_usertype_duo = member.id;
            }else{
              delete m.alt_usertype;
              delete m.alt_usertype_from;
              delete m.alt_usertype_until;
              delete m.alt_usertype_duo;
            }
            return m;
          }
        });
        locationStore.selectedLocation.members = this.members;
        this.getMemberById(member.id);
      } catch(error) {
        this.error = error.message;
      } finally {
        this.loading = false;
        const modalStore = useModalStore();
        modalStore.setModalVisible('selectMember', false);
        modalStore.setModalVisible('addMember', false)
      }
    },
    async deleteMember(memberID) {
      const locationStore = useLocationStore();
      const authStore = useAuthStore();
     
      var params = {

        user: memberID,
        remove: true,
        token : authStore.user.token,
      }
      if(confirm('Lid definitief verwijderen? Alle toekomstige trainingen en reeksen worden hierbij ook verwijderd')){
      
        try {
          this.isLoading = true;

        
          console.log(params);
          const response = await axios.post(import.meta.env.VITE_API_URL+'remove-user.php', params,{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authStore.user.token}`
            }
          });
          
         
          locationStore.fetchLocationMembers();
          

        } catch(error) {
          this.error = error.message;
        } finally {
          this.loading = false;
          const modalStore = useModalStore();
          modalStore.setModalVisible('selectMember', false);
          modalStore.setModalVisible('addMember', false)
          this.selectedMember = null;
          this.selectedMemberLoad = null;
        }
      }
    },
    fullName(member) {
      if(member.firstname || member.lastname) {
        return member.firstname + ' ' + member.lastname;
      } else {
        return "";
      }
      
    },
    async fetchUserData(member) {
      const authStore = useAuthStore();
      try {
        const response = await axios.get(`${import.meta.env.VITE_API_URL}load-user.php`, {
          params: {
            user_id: member.id,
            token: authStore.user.token,
          }
        });
    
        if (response.data) {
          return response.data;
        } else {
          console.error('Invalid user data in the response');
          return null;
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        
        if (error.response?.status === 401) {
          // Specifieke afhandeling voor 401 Unauthorized
          console.error('Authentication error: Unauthorized (401)');
          authStore.logout();
        }
        
      }
    },
    async selectMember(member, showModal) {
      console.log(member);
      console.log(showModal);
      this.selectedMemberLoad = JSON.parse(JSON.stringify(member));
      this.selectedMember = JSON.parse(JSON.stringify(member));
    
      try {
        const userData = await this.fetchUserData(member);
    
        if (userData) {
          this.selectedMember = userData;
          const locationStore = useLocationStore();
    
          const foundMemberIndex = locationStore.selectedLocation.members.findIndex(m => m.id === member.id);
          if (foundMemberIndex !== -1) {
            locationStore.selectedLocation.members[foundMemberIndex] = userData;
          }
        }
      } finally {
        const modalStore = useModalStore();
        if (showModal === true) {
          modalStore.setModalVisible('selectMember', true);
        }
      }
    },
    async getSelectedMemberTimeslots() {
      console.log('memberStore; getSelectedMemberTimeslots');
      const member = this.selectedMember;
      if(member === null){
        return;
      }
      const authStore = useAuthStore();
      try {
        const response = await axios.get(`${import.meta.env.VITE_API_URL}load-user.php?user_id=${this.selectedMember.id}&token=${authStore.user.token}`);
        if (response.data && Array.isArray(response.data.timeslots)) {
          // Add the timeslots from the response to selectedMember
          this.selectedMember.timeslots = [...response.data.timeslots];
          const locationStore = useLocationStore();
    
          const foundMember = locationStore.selectedLocation.members.find(m => m.id === member.id);
          if (foundMember) {
            foundMember.timeslots = [...response.data.timeslots];
          }
        } else {
          // Handle the case when timeslots are not in the expected format
          console.error('Invalid timeslots data in the response');
        }
      } catch (error) {
        // Handle any error that occurred during the API request
        console.error('Error fetching user data:', error);
      }
    },
    deselectMember() {
      this.selectedMember = {}
    },
    openNewMemberForm() {
      const modalStore = useModalStore();
      modalStore.setModalVisible('newMember', true)
    }
  },
  persist: true,
})

