<script setup>
import { useLocationStore } from '../store/LocationStore'
const locationStore = useLocationStore()

</script>

<template>
  <div v-if="locationStore.selectedLocation">
    <div class="px-8 header">
      <h1>
        <span class="text-muted ms-2">{{ locationStore.selectedLocation.name }}&nbsp;</span>
        Contactgegevens
      </h1>
    </div>
    <div class="px-8 my-4">
      <div class="row g-3" style="max-width: 840px;">
        <div class="col-md-6">
          <div class="form-floating">
            <input v-model="locationStore.selectedLocation.email" type="email" class="form-control" id="locationEmail" placeholder="name@example.com">
            <label for="locationEmail">E-mailadres</label>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-floating">
            <input v-model="locationStore.selectedLocation.phone" type="tel" class="form-control" id="locationPhone" placeholder="0612345678">
            <label for="locationPhone">Telefoonnummer</label>
          </div>
        </div>
        <div class="col-12">
          <div class="form-floating">
            <input v-model="locationStore.selectedLocation.address" type="text" class="form-control" id="locationAddress" placeholder="Straatnaam + nr">
            <label for="locationAddress">Adres</label>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-floating">
            <input v-model="locationStore.selectedLocation.zip" type="text" class="form-control" id="locationZip" placeholder="1024 AB">
            <label for="locationZip">Postcode</label>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-floating">
            <input v-model="locationStore.selectedLocation.city" type="text" class="form-control" id="locationCity" placeholder="Den Haag">
            <label for="locationCity">Stad</label>
          </div>
        </div>
        <div class="col-12">
          <button class="btn btn-primary" @click="updateLocationProfile">Opslaan</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>