<script setup>
// import { computed } from "vue";
import { useRoute } from "vue-router";
const route = useRoute(); // Use the useRoute function from Vue Router

import { useMemberStore } from '../store/MemberStore'
const memberStore = useMemberStore();

import { useLocationStore } from '../store/LocationStore'
const locationStore = useLocationStore()

import { onMounted, watchEffect, watch } from 'vue'

onMounted(() => {
  const id = route.params.id;
  if(locationStore.selectedLocation && locationStore.selectedLocation.id) {
    if(id != locationStore.selectedLocation.id) {
      locationStore.setCurrentLocation(id);
      console.log('Setting current location to:', id);
    } else {
      console.log('Location is already selected');
    }
  } else {
    console.log('No selectedLocation found so setting current location to:', id);
    locationStore.setCurrentLocation(id);
  }
});

// watchEffect(() => {
//   // Check if selectedLocation.members is empty, if so, fetch the data
//   // This resolves the problem of that selectedLocation is not set if you refresh on child view from the location
//   // Like on LocationMembers.vue
//   locationStore.setCurrentLocation(route.params.id);
//   // if (locationStore.selectedLocation.members.length <= 0) {
//   //   locationStore.setCurrentLocation(route.params.id);
//   // }
// });

// Watch for changes in $route.params.id and update locationStore.selectedLocation
watch(() => route.params.id, (newId) => {
  locationStore.setCurrentLocation(newId)
});

</script>

<template>
  <main>
    <!-- <h1>{{ $route.params.id }}</h1> -->
    <!-- <router-view :location="locationStore.setCurrentLocation"/> -->
    <router-view/>
  </main>
</template>
