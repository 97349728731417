<!-- MemberSelect.vue -->
<template>
  <v-select 
    placeholder="Kies een lid"
    ref="memberSelect"
    id="selectedMember"
    v-model="selectedMember"
    @option:selected="selectMember"
    :options="membersArrayWithFullName"
    label="fullname"
  >
    <template #selected-option="{ fullname, usertype }">
      <div style="display: flex; align-items: center;">
        <font-awesome-icon icon="fas fa-user" /><strong>{{ fullname }} ({{ usertype }})</strong>
      </div>
    </template>
  </v-select>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useLocationStore } from '../store/LocationStore'
import { useMemberStore } from '../store/MemberStore'

const memberStore = useMemberStore();
const locationStore = useLocationStore();

// Local state bound to vue-select
const selectedMember = ref(memberStore.selectedMember);

// Watch the shared state and update the local state
watch(() => memberStore.selectedMember, newVal => {
  selectedMember.value = newVal;
});

// Update the shared state when the local state changes
watch(selectedMember, newVal => {
  memberStore.selectedMember = newVal;
});

const membersArrayWithFullName = computed(() => {
  const members = locationStore.selectedLocation?.members;
  if (!members) {
    return [];
  }

  const membersArray = Object.values(members);
  return membersArray.map(member => ({
    ...member,
    fullname: `${member.firstname} ${member.lastname} (${member.usertype})`
  }));
});


const selectMember = (member) => {
  memberStore.selectMember(member, false);
};

</script>
