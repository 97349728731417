<script setup>
import Modal from '../components/Modal.vue'; // Import the modal component
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';
import { capitalizeFirstLetter } from '../helpers/functions'

import { useMemberStore } from '../store/MemberStore'
import { useLocationStore } from '../store/LocationStore'
import { useModalStore } from '../store/ModalStore'

const memberStore = useMemberStore()
const locationStore = useLocationStore()
const modalStore = useModalStore()

const memberSchema = Yup.object().shape({
    firstname: Yup.string().required('Voornaam is verplicht'),
    lastname: Yup.string().required('Achternaam is verplicht'),
    email: Yup.string().required('E-mailadres is verplicht').email(),
    phone: Yup.string(),
    location: Yup.string().required('Locatie keuze is verplicht'),
    membership: Yup.string().required('Abonnement keuze is verplicht'),
});

function onSubmitNewMember(values, { setErrors }) {
  return memberStore.addMember(values)
    .then(newMember => {
      console.log('Member added successfully:', newMember);
      console.log('Now adding new member to location');
      locationStore.addMemberToLocation(newMember);
      // You can perform any additional actions here, such as displaying a success message
    })
    .catch(error => {
      console.error('Error adding member:', error);
      setErrors({ apiError: error });
    });
}

</script>

<template>
  <div class="demo-app">
    <div class="demo-app-main">
      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2">Leden <span class="badge bg-success">{{ memberStore.membersCount }}</span></h1>
            <a @click="memberStore.openNewMemberForm" class="btn btn-success">Nieuw lid toevoegen</a>
          </div>
        </div>
        <div class="col-12">
          <div class="row g2">
            <div class="col-12 col-lg-4">
              <input class="form-control" type="search" v-model="memberStore.search" placeholder="Zoek leden..">
            </div>
            <div class="col-12 col-lg-4">
              <select class="form-select" name="memberships" id="memberships" v-model="memberStore.selectedMembership">
                <option value="all">Alle</option>
                <option v-for="(membership, index) in memberStore.memberships" :key="index" :value="membership">
                  {{ capitalizeFirstLetter(membership) }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3">
          <div class="row g-2" v-if="memberStore.filteredMembers">
            <div 
              v-for="member in memberStore.filteredMembers" 
              :key="member.id" class="col-12 col-md-6 col-lg-4">
              <div class="card" style="min-height: 100%;" @click="memberStore.selectMember(member)">
                <div class="card-body position-relative">
                  <span class="label" style="position:absolute; top:3px;right:5px;"><small class="text-muted">ID: {{ member.id }}</small></span>
                  <h5 class="card-title mb-0 fw-bold">{{ memberStore.fullName(member) }}</h5>
                  <span class="d-block"><strong>E-mail:</strong> {{ member.email }}</span>
                  <span class="d-block"><strong>Abonnement:</strong> {{ member.usertype }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal :id="'selectMember'" :title="memberStore.fullName(memberStore.selectedMember)" v-if="modalStore.isModalVisible('selectMember')" storeName="memberStore" functionName="deselectMember">
      <div>
        <div class="row g-3">
          <div class="col-12">
            <span class="badge bg-secondary">ID: {{memberStore.selectedMember.id}}</span>
          </div>
          <div class="col-md-6">
            <div class="form-floating">
              <input v-model="memberStore.selectedMember.firstname" type="text" class="form-control" id="memberFirstname" placeholder="John">
              <label for="memberFirstname">Voornaam</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating">
              <input v-model="memberStore.selectedMember.lastname" type="text" class="form-control" id="memberLastname" placeholder="Doe">
              <label for="memberLastname">Achternaam</label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating">
              <input v-model="memberStore.selectedMember.email" type="text" class="form-control" id="memberEmail" placeholder="example@example.com">
              <label for="memberEmail">E-mailadres</label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating">
              <input v-model="memberStore.selectedMember.phone" type="text" class="form-control" id="memberPhone" placeholder="07012341234">
              <label for="memberPhone">Telefoonnummer</label>
            </div>
          </div>
          <div class="col-12 mt-4">
            <h5 class="fw-bold">Abonnement</h5>
            <select class="form-select" id="selectedMembershipMemberships" v-model="memberStore.selectedMember.membership">
              <option v-for="(membership, index) in memberStore.memberships" :key="index" :value="membership.value" :selected="memberStore.selectedMember.membership">
                {{ membership.text }}
              </option>
            </select>
          </div>
          <div class="col-12 mt-4">
            <h5 class="fw-bold">Notificaties</h5>
            <div class="form-check">
              <input v-model="memberStore.selectedMember.notify" type="checkbox" class="form-check-input" id="memberNotify" disabled>
              <label class="form-check-label" for="memberNotify">Heeft e-mail notificaties aan?</label>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <button :disabled="!memberStore.isSelectedMemberChanged" class="btn btn-primary" @click="memberStore.updateMember(memberStore.selectedMember)">Opslaan</button>
        </div>
      </div>
    </Modal>

    <Modal :id="'newMember'" :title="'Nieuw lid toevoegen'" v-if="modalStore.isModalVisible('newMember')">
      <div>
        <Form @submit="onSubmitNewMember" :validation-schema="memberSchema" v-slot="{ errors, isSubmitting }">
          <div class="row g-3">
            <div class="col-md-6">
              <div class="form-floating">
                <Field name="firstname" type="text" class="form-control" placeholder="Joe" :class="{ 'is-invalid': errors.firstname }" />
                <label for="firstname">Voornaam</label>
                <div class="invalid-feedback">{{errors.firstname}}</div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-floating">
                <Field name="lastname" type="text" class="form-control" placeholder="Doe" :class="{ 'is-invalid': errors.lastname }" />
                <label for="lastname">Achternaam</label>
                <div class="invalid-feedback">{{errors.lastname}}</div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-floating">
                <Field name="email" type="email" class="form-control" placeholder="Doe" :class="{ 'is-invalid': errors.email }" />
                <label for="email">E-mailadres</label>
                <div class="invalid-feedback">{{errors.email}}</div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-floating">
                <Field name="phone" type="text" class="form-control" placeholder="070-12341234" :class="{ 'is-invalid': errors.phone }" />
                <label for="phone">Telefoonnummer</label>
                <div class="invalid-feedback">{{errors.phone}}</div>
              </div>
            </div>
            <div class="col-12 mt-4">
              <h5 class="fw-bold">Locatie</h5>
              <Field name="location" as="select" class="form-select" :class="{ 'is-invalid': errors.location}">
                <option value="" disabled>Kies een locatie</option>
                <option v-for="(locationData, index) in locationStore.locations" :key="index" :value="locationData.id">
                  {{ locationData.name }}
                </option>
              </Field>
              <div class="invalid-feedback">{{errors.location}}</div>
            </div>
            <div class="col-12 mt-4">
              <h5 class="fw-bold">Abonnement</h5>
              <Field name="membership" as="select" class="form-select" :class="{ 'is-invalid': errors.membership}">
                <option value="" disabled>Kies een abonnement</option>
                <option v-for="(membershipData, index) in memberStore.memberships" :key="index" :value="membershipData.value">
                  {{ membershipData.text }}
                </option>
              </Field>
              <div class="invalid-feedback">{{errors.membership}}</div>
            </div>
            <div class="col-12 mt-4">
              <h5 class="fw-bold">Notificaties</h5>
              <div class="form-check">
                <Field name="notifications" type="checkbox" class="form-check-input" :class="{ 'is-invalid': errors.notifications }" :value="false" />
                <label class="form-check-label" for="notifications">Heeft e-mail notificaties aan?</label>
                <div class="invalid-feedback">{{errors.notifcations}}</div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <button class="btn btn-primary" :disabled="isSubmitting">
              <span v-show="isSubmitting" class="spinner-border spinner-border-sm me-1"></span>
              Opslaan
            </button>
            <!-- <button class="btn btn-primary" @click="memberStore.addMember(memberStore.newMember)">Opslaan</button> -->
          </div>
          <div v-if="errors.apiError" class="alert alert-danger mt-3 mb-0">{{errors.apiError}}</div>
        </Form>
      </div>
    </Modal>
  
  </div>
</template>

<style scoped>

</style>