import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "../store/AuthStore";
import { useMemberStore } from "../store/MemberStore";

import Locations from "../views/Locations.vue";
import Location from "../views/Location.vue";
import Login from "../views/Login.vue";
import ActivateAccount from "../views/ActivateAccount.vue";
import PasswordReset from "../views/PasswordReset.vue";
import PasswordUpdate from "../views/PasswordUpdate.vue";
import Dashboard from "../views/Dashboard.vue";
import Members from "../views/Members.vue";
import UserHome from "../views/UserHome.vue";
import UserProfile from "../views/UserProfile.vue";
import UserCalendar from "../views/UserCalendar.vue";
import LocationCalendar from "../components/LocationCalendar.vue";
import LocationRecurring from "../components/LocationRecurring.vue";
import LocationProfile from "../components/LocationProfile.vue";
import LocationMembers from "../components/LocationMembers.vue";
import LocationSettings from "../components/LocationSettings.vue";

import { formatISO, getISOWeek, getYear } from "date-fns";

import { useLocationStore } from "../store/LocationStore"; // Adjust the path if necessary

export const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes: [
    { path: "/login", component: Login },
    { path: "/account-activeren", component: ActivateAccount },
    { path: "/password-reset", component: PasswordReset },
    { path: "/password-update", component: PasswordUpdate },
    { path: "/members", component: Members },
    { path: "/locations", component: Locations },
    { path: "/dashboard", component: Dashboard },
    { path: "/", component: UserHome },
    { path: "/profile", component: UserProfile },
    {
      path: "/calendar",
      component: UserCalendar,
      beforeEnter: (to, from, next) => {
        // If the week query parameter is not present in the URL
        if (!to.query.week) {
          // Redirect to the same route with the current week number as the query parameter
          const today = new Date();
          const currentWeekNumber = getISOWeek(today);
          const currentYear = getYear(today);
          next({
            path: "/calendar",
            query: { week: currentWeekNumber, year: currentYear },
          });
        } else {
          // If the week query parameter is present, continue as normal
          next();
        }
      },
    },
    {
      path: "/location/:id",
      name: "location",
      component: Location,
      redirect: {
        name: "profile",
      },
      children: [
        {
          path: "calendar",
          name: "calendar",
          component: LocationCalendar,
          meta: { requiresLocation: true },
        },
        {
          path: "recurring",
          name: "recurring",
          component: LocationRecurring,
          meta: { requiresLocation: true },
        },
        {
          path: "members",
          name: "members",
          component: LocationMembers,
          meta: { requiresLocation: true },
        },
        {
          // Profile settings will be render inside Profile's <router-view></router-view>
          // when /location/:id/profile
          path: "profile",
          name: "profile",
          component: LocationProfile,
          meta: { requiresLocation: true },
        },
        {
          path: "settings",
          name: "settings",
          component: LocationSettings,
          meta: { requiresLocation: true },
        },
      ],
    },
    // // otherwise redirect to home
    { path: "/:pathMatch(.*)*", redirect: "/" },
  ],
});

router.beforeEach(async (to) => {
  const publicPages = [
    "/login",
    "/password-reset",
    "/password-update",
    "/account-activeren",
  ];
  const authRequired = !publicPages.includes(to.path);
  const authStore = useAuthStore();
  const memberStore = useMemberStore();
  const locationStore = useLocationStore();

  memberStore.search = "";
  memberStore.selectedMembership = "all";

  // If the route requires authentication and user is not logged in, redirect to login
  if (authRequired && !authStore.user) {
    return { path: "/login" };
  }

  if (authStore.user) {
    const userType = authStore.user.usertype;

    // Define allowed routes for regular users
    const allowedUserRoutes = ["/", "/calendar", "/profile"];

    // Redirect based on user type for root path
    if (to.path === "/") {
      if (userType === "admin") return "/locations";
      if (userType === "support") return "/dashboard";
      if (userType === "trainer") return "/location/1/calendar";
    }

    // For users who are not admin, trainer, or support
    if (!["admin", "trainer", "support"].includes(userType)) {
      // If trying to access a route not in allowedUserRoutes, redirect to home
      if (!allowedUserRoutes.includes(to.path)) {
        return { path: "/" };
      }
    }

    // Check if the route requires a selected location
    // if (to.matched.some((record) => record.meta.requiresLocation)) {
    //   if (!locationStore.selectedLocation) {
    //     // Redirect to locations page if no location is selected
    //     return { path: "/locations" };
    //   }
    // }

    // Additional route guards for specific user types can be added here if needed
    // For example:
    // if (userType === 'support' && to.path.startsWith('/location/')) {
    //   return { path: '/dashboard' };
    // }
  }
});

export default router;
