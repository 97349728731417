<script setup>
  import { defineComponent, ref, onMounted, watch } from 'vue'
  import { useAuthStore, useLocationStore, useModalStore } from '../store'

  import Modal from '../components/Modal.vue'

  const locationStore = useLocationStore()
    const modalStore = useModalStore()
</script>

<style>
table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

th, td {
    border: 1px solid #ddd;
    padding: 2px;
    text-align: center;
}

th {
    background-color: #f2f2f2;
}

input[type="time"], input[type="date"] {
    border: 1px solid #ccc;
    padding: 5px;
    /* width: 80px; */
}

button {
    padding: 5px;
}
.time-slot {
  white-space: nowrap;
}
</style>
<script>

export default {
  data() {
    return {
      tableWeekdays : ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Zondag'],
      jsonWeekdays : ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
      emptyHours: [{
                    default: false,
                    extra: false,
                    intro: false,
                    closed: false
                },
                {
                    default: false,
                    extra: false,
                    intro: false,
                    closed: false
                },
                {
                    default: false,
                    extra: false,
                    intro: false,
                    closed: false
                },
                {
                    default: false,
                    extra: false,
                    intro: false,
                    closed: false
                },
                {
                    default: false,
                    extra: false,
                    intro: false,
                    closed: false
                },
                {
                    default: false,
                    extra: false,
                    intro: false,
                    closed: false
                },
                {
                    default: false,
                    extra: false,
                    intro: false,
                    closed: false
                }
            ]
    }
  },
  methods: {

    handleClick(event) {
      const clickedElement = event.target;
      const timeType = clickedElement.getAttribute('data-type');
      // Controleren of het geklikte element een knop is met de klasse 'button--add'
      if (clickedElement.tagName === 'BUTTON' && clickedElement.classList.contains('button--add')) {
        this.addTimeSlot(clickedElement,timeType);
      }

      // Controleren of het geklikte element een knop is met de klasse 'button--remove'
      if (clickedElement.tagName === 'BUTTON' && clickedElement.classList.contains('button--remove')) {
        this.removeTime(clickedElement,timeType);
      }

      if (clickedElement.tagName === 'BUTTON' && clickedElement.classList.contains('button--add-date')) {
        this.addToDateField(clickedElement)
      }

      if (clickedElement.tagName === 'BUTTON' && clickedElement.classList.contains('button--remove-date')) {
        this.removeToDateField(clickedElement)
      }

      // Controleren of het geklikte element een knop is met de klasse 'button--remove'
      if (clickedElement.tagName === 'BUTTON' && clickedElement.classList.contains('button--remove-row')) {
        this.removeRow(clickedElement)
      }

     
    },



    isEmpty(obj) {
        for (var key in obj) {
            if (obj[key] !== null && obj[key] !== "" && obj[key] !== false && (Array.isArray(obj[key]) ? obj[key].length !== 0 : true)) {
                return false;
            }
        }
        return true;
    },

    extractDataFromTable(tableId) {
        let tableBody = document.getElementById(tableId).querySelector('tbody');
        let rows = Array.from(tableBody.querySelectorAll('tr')); // Convert to an array to use the index
        let weekData = [];

        this.jsonWeekdays.forEach((dayName, index) => {
            let dayData = {};
            let row = rows[this.tableWeekdays.indexOf(dayName)]; // Find the correct row based on table order
            let cells = row.querySelectorAll('td');

            ['default', 'extra', 'intro', 'closed'].forEach((timeType, cellIndex) => {
                let timeSlots = cells[cellIndex + 1].querySelectorAll('.time-slot');
                if (timeSlots.length) {
                    dayData[timeType] = [];
                    timeSlots.forEach(slot => {
                        let times = slot.querySelectorAll('input[type="time"]');
                        if (timeType === 'closed') {
                            dayData[timeType].push([times[0].value, times[1].value]);
                        } else {
                            dayData[timeType].push(times[0].value, times[1].value);
                        }
                    });
                } else {
                    dayData[timeType] = false;
                }
            });

            weekData.push(dayData);
        });

        return weekData;
    },
    extractCustomDataFromTable(tableId) {
        let tableBody = document.getElementById(tableId).querySelector('tbody');
        let rows = tableBody.querySelectorAll('tr');
        let customData = {};

        rows.forEach(row => {
            let dayData = {};
            let cells = row.querySelectorAll('td');
            let fromDate = cells[0].querySelector('input[type="date"]').value;
            let toDate = cells[1].querySelector('input[type="date"]') ? cells[1].querySelector('input[type="date"]').value : false;
            let yearly = cells[2].querySelector('input[type="checkbox"]').checked;

            ['default', 'extra', 'intro', 'closed'].forEach((timeType, index) => {
                let timeSlots = cells[index + 3].querySelectorAll('.time-slot');
                if (timeSlots.length) {
                    dayData[timeType] = [];
                    timeSlots.forEach(slot => {
                        let times = slot.querySelectorAll('input[type="time"]');
                        if (timeType === 'closed') {
                            dayData[timeType].push([times[0].value, times[1].value]);
                        } else {
                            dayData[timeType].push(times[0].value, times[1].value);
                        }
                    });
                } else {
                    dayData[timeType] = false;
                }
            });


            customData[fromDate] = {
                data: dayData,
                toDate: toDate,
                yearly: yearly
            };
        });

        return customData;
    },
    saveAgenda(force) {
        let futureOpenHoursData = {
            from: document.getElementById('futureFromDate').value,
            week: this.extractDataFromTable('futureOpenHoursTable')
        };

        let customOpenHoursData = this.extractCustomDataFromTable('customOpenHoursTable');

        let jsonData = {
            currentOpenHours: this.extractDataFromTable('currentOpenHoursTable'),
            futureOpenHours: this.isEmpty(futureOpenHoursData.from) ? false : futureOpenHoursData,
            customOpenHours: this.isEmpty(customOpenHoursData) ? false : customOpenHoursData
        };
        let openHours = JSON.stringify(jsonData);

        //document.getElementById('jsonOutput').innerText = openHours;

        const locationStore = useLocationStore();
        // console.log('force: '+force);
        locationStore.updateLocationAgenda(openHours,force);
    },
    addTimeSlot(buttonElement, timeType) {
        let cell = buttonElement.parentElement;

        let timeSlotDiv = document.createElement('div');
        timeSlotDiv.className = 'time-slot';
        timeSlotDiv.innerHTML = `<input type="time" value="00:00"> - <input type="time" value="00:00"> <button class="button btn btn-red btn-sm m-1 button--remove" data-type="${timeType}">-</button>`;
        cell.insertBefore(timeSlotDiv, buttonElement);

        // Als het tijdType "closed" is, laat dan altijd de "+" knop zien
        if (timeType === 'closed') {
            buttonElement.style.display = 'inline';
        } else {
            // Verberg de "+" knop voor 'extra' en 'intro' als het aantal tijdssloten het maximum heeft bereikt
            let maxSlots = (timeType !== 'closed') ? 1 : Number.MAX_VALUE;
            if (cell.querySelectorAll('.time-slot').length >= maxSlots) {
                buttonElement.style.display = 'none';
            }
        }
    },
    createRow(tableBodyOrRow, day, dayName = null, date = null) {
        if (!day) return;
        let row;
        if (tableBodyOrRow.tagName === 'TR') {
            row = tableBodyOrRow;
        } else {
            row = tableBodyOrRow.insertRow();
        }

        if (date) { // This means it's a custom row
            let fromDateCell = row.insertCell();
            fromDateCell.innerHTML = `<input type="date" value="${date}">`;

            let toDateCell = row.insertCell();
            toDateCell.innerHTML = day.toDate ? `<input type="date" value="${day.toDate}"> <button class="button btn btn-red btn-sm m-1 button--remove-date">-</button>` : '<button class="button btn btn-success btn-sm m-1 button--add-date">+</button>';

            let yearlyCell = row.insertCell();
            yearlyCell.innerHTML = `<input type="checkbox" ${day.yearly ? 'checked' : ''}>`;

            // // Actie Veld
            // let actionCell = row.insertCell();
            // actionCell.innerHTML = `<button @click="removeRow(this)">-</button>`;

            day = day.data; // Update the day variable to be just the time data

            console.log(day);
        } else if (dayName) {
            row.insertCell().innerText = dayName;
        }

        ['default', 'extra', 'intro', 'closed'].forEach(timeType => {
            let cell = row.insertCell();
            let timeData = day[timeType];

            if (timeData && timeData.length > 0) {
                if (['default', 'intro', 'extra'].includes(timeType) && typeof timeData[0] === 'string') {
                    timeData = [timeData];
                }

                timeData.forEach(time => {
                    let timeSlotDiv = document.createElement('div');
                    timeSlotDiv.className = 'time-slot';
                    timeSlotDiv.innerHTML = `<input type="time" value="${time[0] || '00:00'}"> - <input type="time" value="${time[1] || '00:00'}">`;
                    timeSlotDiv.innerHTML += ` <button class="button btn btn-red btn-sm m-1 button--remove" data-type="${timeType}">-</button>`;
                    cell.appendChild(timeSlotDiv);
                });
            }

            let addButton = document.createElement('button');
            addButton.innerHTML = '+';
            addButton.className = 'button btn btn-success btn-sm m-1 button--add';
            addButton.setAttribute('data-type',timeType);
            //addButton.innerHTML = ` <button  class="button btn btn-success btn-sm m-1 button--add" data-type="${timeType}">+</button>`;
            // 
            // addButton.onclick = function() {
            //     addTimeSlot(this, timeType);
            // };
            cell.appendChild(addButton);
            if ((timeType !== 'closed') && timeData && timeData.length >= 1) {
                addButton.style.display = 'none';
            }
        });
        
        if (date) { // This means it's a custom row
            // // Actie Veld
            let actionCell = row.insertCell();
            actionCell.innerHTML = `<button class="button btn btn-red btn-sm m-1 button--remove-row">-</button>`;

            day = day.data; // Update the day variable to be just the time data
        }
    },
    populateTable(tableId, hoursData, isCustom = false) {
        let tableElement = document.getElementById(tableId); 
    
        if (tableElement === null) {
          console.error(`Element met ID ${tableId} niet gevonden.`);
          return;
        }
        let tableBody = document.getElementById(tableId).querySelector('tbody');
        if (isCustom) {
            Object.entries(hoursData).forEach(([date, dayInfo]) => {
                this.createRow(tableBody, dayInfo, null, date);
            });
        } else {
            this.tableWeekdays.forEach((dayName, index) => {
                let jsonDataIndex = this.jsonWeekdays.indexOf(dayName);
                let dayData = hoursData[jsonDataIndex] || {};
                this.createRow(tableBody, dayData, dayName);
            });
        }
    },
    showFutureOpenHours() {
        const today = new Date();
        const futureDate = new Date(this.hours.futureOpenHours.from);

        if (this.hours.futureOpenHours.from && futureDate <= today) {
            this.hours.currentOpenHours = this.hours.futureOpenHours.week;
            this.hours.futureOpenHours.from = false;//''; // Clear the from date
            this.hours.futureOpenHours.week = this.emptyHours;

            populateTable('currentOpenHoursTable', this.hours.currentOpenHours);
        }

        populateTable('futureOpenHoursTable', this.hours.futureOpenHours.week);
    },
    clearFutureOpenHours() {
        this.hours.futureOpenHours = {
            from: '',
            week: this.emptyHours
        };

        document.getElementById('futureFromDate').value = '';
        populateTable('futureOpenHoursTable', this.hours.futureOpenHours.week);
    },



    addCustomDateRow() {
        let tableBody = document.getElementById('customOpenHoursTable').querySelector('tbody');
        let row = tableBody.insertRow();

        // Van Datum
        let fromDateCell = row.insertCell();
        fromDateCell.innerHTML = `<input type="date" value="${new Date().toISOString().split('T')[0]}">`;

        // Tot Datum met de '+' knop
        let toDateCell = row.insertCell();
        toDateCell.innerHTML = `<button  class="button btn btn-success btn-sm m-1 button--add-date">+</button>`;

        // Jaarlijks Checkbox
        let yearlyCell = row.insertCell();
        yearlyCell.innerHTML = `<input type="checkbox">`;

        // Tijd velden
        let day = {
            default: [],
            extra: [],
            intro: [],
            closed: []
        };
        this.createRow(row, day); // Hier stuur je de rij direct naar de functie

        // Actie Veld
        let actionCell = row.insertCell();
        actionCell.innerHTML = `<button class="button btn btn-red btn-sm m-1 button--remove-row">-</button>`;
    },

    removeTime(buttonElement, timeType) {
        let timeSlotDiv = buttonElement.parentElement;
        let cell = timeSlotDiv.parentElement;
        timeSlotDiv.remove();

        if (cell.querySelectorAll('.time-slot').length < 1 || timeType === 'closed') {
            // Toon de "+" knop opnieuw voor alle tijdTypes als het aantal tijdssloten lager is dan het maximum
            cell.querySelector('button').style.display = 'inline';
        }
    },
    removeRow(buttonElement) {
        let row = buttonElement.closest('tr');
        row.remove();
    },
    addToDateField(button) {
        let cell = button.parentElement;
        cell.innerHTML = `<input type="date" value="${new Date().toISOString().split('T')[0]}">
                          <button class="button btn btn-red btn-sm m-1 button--remove-date">-</button>`;
    },
    removeToDateField(button) {
        let cell = button.parentElement;
        cell.innerHTML = `<button class="button btn btn-success btn-sm m-1 button--add-date">+</button>`;
    }

   
  },
  mounted() {
  this.$nextTick(function() {
    // Code die afhankelijk is van de DOM hier
    const locationStore = useLocationStore()
    this.hours = locationStore.selectedLocation.openHours;
    this.populateTable('currentOpenHoursTable', this.hours.currentOpenHours || []);
    this.populateTable('futureOpenHoursTable', (this.hours.futureOpenHours && this.hours.futureOpenHours.week) || []);
    this.populateTable('customOpenHoursTable', this.hours.customOpenHours || {}, true);
    
    });
  }
}




</script>

<template>
  <div @click="handleClick">
    <div class="px-8 header">
      <h1>
        <span class="text-muted ms-2">{{ locationStore.selectedLocation.name }}&nbsp;</span>
        Openingstijden
      </h1>
    </div>
    <div class="px-8 my-4">
      <div class="row">
        <div class="col-12">

          <h3>Openingstijden</h3>
          <table class="table table-striped gs-7 gy-7 gx-7" id="currentOpenHoursTable">
              <thead>
                  <tr class="fw-semibold fs-6 text-gray-800 border-bottom border-gray-200">
                      <th>Dag</th>
                      <th>Openingstijden</th>
                      <th>Dubbele tijsloten</th>
                      <th>KMT blokken</th>
                      <th>Pauzes/Gesloten</th>
                  </tr>
              </thead>
              <tbody>
                  <!-- Rows will be populated by JavaScript -->
              </tbody>
          </table>

          <h3>Nieuwe Openingstijden</h3>
          <!-- <button @click="addFutureOpenHours()">Voeg Future Openingstijden Toe</button> -->



          <p>Vanaf: <input type="date" id="futureFromDate"></p>
          <table id="futureOpenHoursTable">
              <thead>
                  <tr>
                      <th>Dag</th>
                      <th>Openingstijden</th>
                      <th>Dubbele tijsloten</th>
                      <th>KMT blokken</th>
                      <th>Pauzes/Gesloten</th>
                  </tr>
              </thead>
              <tbody>
                  <!-- Rows will be populated by JavaScript -->
              </tbody>
          </table>

          <!-- <button @click="clearFutureOpenHours()">Maak Future Openingstijden Tabel Leeg</button> -->


          <h3>Custom Openingstijden</h3>
          <table id="customOpenHoursTable">
              <thead>
                  <tr>
                      <th>Datum</th>
                      <th>Datum Tot</th>
                      <th>Jaarlijks</th>
                      <th>Openingstijden</th>
                      <th>Dubbele tijsloten</th>
                      <th>KMT blokken</th>
                      <th>Pauzes/Gesloten</th>
                      <th></th>
                  </tr>
              </thead>
              <tbody>
                  <!-- Rows will be populated by JavaScript -->
              </tbody>
              <tfoot>
                <tr><td colspan="8"><button @click="addCustomDateRow()" class="btn btn-success btn-sm m-1">+</button></td></tr>
              </tfoot>
          </table>

          <br><br>
          <button @click="saveAgenda(false)" class="btn btn-primary">Opslaan</button>
          <pre id="jsonOutput"></pre>

        </div>
      </div>
    </div>
  </div>

  <Modal :id="'notifyAgenda'" title="" v-if="modalStore.isModalVisible('notifyAgenda')">
      <div>
        <div class="row g-3">
          <div class="col">
            <span v-for="(notify,index) in locationStore.selectedLocation.notify" :key="index">
                <strong>{{ index }}</strong>
                <ul>
                    <li v-for="(timeslot,index) in notify.timeslots" :key="index">
                        {{ timeslot.date }} {{ timeslot.starttime }}-{{ timeslot.endtime }}
                    </li>
                </ul>
            </span>
        </div>
        </div>
        <div class="row mt-3">
          <div class="button-group">
            <button type="button" class="btn btn-secondary me-2" @click="modalStore.setModalVisible('notifyAgenda', false)">Annuleren</button>
            <button type="button" class="btn btn-primary me-2" @click="saveAgenda(true)">Doorvoeren</button>
          </div>
        </div>
      </div>
  </Modal>
</template>

<style scoped>

</style>