<script setup>
import { useLocationStore } from '../store/LocationStore';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '../store';
import { capitalizeFirstLetter } from '../helpers/functions';

// const store = useLocationStore();
const authStore = useAuthStore();
// const { user: authUser } = storeToRefs(authStore);

</script>

<script>
export default {
  data() {
    return {
      // calendarFeedURL: 'https://dev.suprevo.com/booking/ical.php'
    }
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text)
        .then(() => {
          // Success message
          console.log('Text copied to clipboard');
        })
        .catch(err => {
          // Error message
          console.error('Could not copy text: ', err);
        });
    }
  }
}
</script>

<template>
  <main>
    <div class="box">
      <div class="box__header">
        <h6>Profiel instellingen</h6>
        <a class="btn btn-red btn-sm" @click="authStore.logout()">Sign out</a>
      </div>
      <div class="box__body px-3 mb-5" v-if="authStore.user">
        <h5 class="fw-bold">Profiel</h5>
        <div class="form-group">
          <label for="email" class="fw-bold mb-1">E-mailadres</label>
          <input v-model="authStore.user.email" type="text" class="form-control" id="email" disabled>
        </div>
        <div class="form-group mt-3">
          <label for="phone" class="fw-bold mb-1">Telefoonnummer</label>
          <input v-model="authStore.user.phone" type="text" class="form-control" id="phone" disabled>
        </div>
        <hr>
        <div class="form-group">
          <label class="fw-bold mb-1">Locatie</label>
          <input :value="capitalizeFirstLetter(authStore.user.location?.name)" type="text" class="form-control" readonly disabled>
        </div>
        <div class="form-group mt-3">
          <label class="fw-bold mb-1">Abonnement</label>
          <input :value="capitalizeFirstLetter(authStore.user.usertype)" type="text" class="form-control" readonly disabled>
        </div>
        <hr>
        <button class="btn btn-green">Opslaan</button>
        <!-- <template v-if="authStore.userLevel == 1">
          <hr>
          <h5 class="fw-bold">Instellingen</h5>
          <div class="d-flex">
            <label for="memberNotify" class="switch flex-grow-0 flex-shrink-0 me-2">
              <input type="checkbox" id="memberNotify" v-model="authStore.user.notify">
              <span class="slider round"></span>
            </label>
            <strong class="d-inline-block">Herinnering ontvangen 1 uur voor de training?</strong>
          </div>
          <hr>       
          <div class="d-flex align-items-center">
            <label for="calenderFeed" class="switch flex-grow-0 flex-shrink-0 me-2">
              <input type="checkbox" id="calenderFeed" @click="authStore.icalFeed" v-model="authStore.user.ical">
              <span class="slider round"></span>
            </label>
            <strong class="d-block">Kalender URL feed aanmaken</strong>
          </div>
          <div v-if="authStore.user.ical_url">
            <hr>
            <strong  class="d-block mb-2">Kopieër kalender feed URL</strong>
            <div class="d-flex">
              <input v-if="authStore.user.ical_url" type="text" class="form-control" :value="authStore.user.ical_url" readonly>
              <button class="btn copy" @click="copyToClipboard(authStore.user.ical_url)">Kopieer</button>
            </div>
          </div>
          <hr>      
        </template>  -->
      </div>
    </div>
  </main>
</template>

<style scoped>
 main {
  height: calc(100% - 70px);
  width: 100%;
  align-self: start;
 }
 h3 {
  font-weight: bold;
 }
 table.trainingen {
  width: 100%;
 }
 thead {
  height: 40px;
 }
 th {
  font-weight: bold;
  color: black;
 }
 tbody tr:nth-child(odd) {
  background: rgba(255,255,255,1);
 }
 td {
  padding: 5px 0;
 }
 table.trainingen button {
  height: 48px;
  width: 48px;
 }
</style>