<template>
  <div class="modal" :class="{ 'show': isModalVisible(id) }" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div v-if="title" class="modal-header">
          <h3 class="modal-title fw-bold">{{ title }}</h3>
          <button type="button" class="btn-red btn-close" data-bs-dismiss="modal" aria-label="Close" @click="setModalVisible(id, false)">
            <font-awesome-icon icon="far fa-times" />
          </button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModal">Close</button>
        </div> -->
      </div>
    </div>
  </div>
  <div v-if="isModalVisible(id)" class="modal-overlay" @click="setModalVisible(id, false)"></div>
</template>

<script>
import { useModalStore } from '../store/ModalStore'

export default {
  props: {
    id: {
      type: String,
    },
    title: {
      type: String,
    },
    storeName: {
      type: String,
    },
    functionName: {
      type: String,
    },
  },
  setup(props) {
    const modalStore = useModalStore()

    const isModalVisible = (id) => {
      return modalStore.isModalVisible(id)
    }

    const setModalVisible = (id, visible) => {
      modalStore.setModalVisible(id, visible)
    }

    const closeModal = () => {

      modalStore.setModalVisible(props.id, false, props.storeName, props.functionName)
    }

    return {
      isModalVisible,
      setModalVisible,
      closeModal
    }
  },
}
</script>

<style scoped>
/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  width: 100%;
  z-index: 2000;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  z-index: 1999;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.show {
  display: flex;
}

.modal-content {
  max-width: 600px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h3 {
  margin: 0;
}

.modal-header button {
  /* background: none; */
  border: none;
  cursor: pointer;
}

.modal-body {
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

</style>