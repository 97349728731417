<script setup>
import Modal from '../components/Modal.vue'; // Import the modal component
import { ref } from 'vue'
import moment from 'moment'
import 'moment/dist/locale/nl'
import { capitalizeFirstLetter } from '../helpers/functions'

import { useAuthStore } from '../store/AuthStore'
import { useMemberStore } from '../store/MemberStore'
import { useLocationStore } from '../store/LocationStore'
import { useEventStore } from '../store/EventStore'
import { useModalStore } from '../store/ModalStore'
const authStore = useAuthStore()
const memberStore = useMemberStore()
const locationStore = useLocationStore()
const modalStore = useModalStore()
const eventStore = useEventStore()

let currentTab = ref('Tab1')


</script>

<script>

export default {
  // data() {},
  methods: {
    removeTimeslotFromMember(clickInfo) {
      console.log(clickInfo);
      var eventMembers = clickInfo.extendedProps.users;
      const memberStore = useMemberStore()
      var userId = memberStore.selectedMember.id;
      if (eventMembers.includes(userId)) {
        const eventStore = useEventStore()
        eventStore.removeEvent(clickInfo);
      } else {
        const eventStore = useEventStore()
        eventStore.selectEvent(clickInfo);
      }
    },
    removeEvent() {
      const eventStore = useEventStore()
      const authStore = useAuthStore()
      eventStore.updateEvent().then((result) => {
        console.log('removeEvent finished with result:', result);
      }).catch((error) => {
        console.log('removeEvent failed with error:', error);
        // Handle the error here
      });
    },
  }
}
</script>


<template>
  <div>
    <div class="px-8 header">
      <h1><span class="text-muted ms-2">{{ locationStore.selectedLocation?.name }}&nbsp;</span>Leden <span class="badge bg-success ms-2">{{ locationStore.locationMemberCount }}</span></h1>
    </div>
    <div class="px-8 my-4">
      <div class="row g2">
        <div class="col-12 col-lg-4">
          <input class="form-control" type="search" v-model="memberStore.search" placeholder="Zoek leden..">
        </div>
        <div class="col-12 col-lg-4">
          <select class="form-select" name="memberships" id="memberships" v-model="memberStore.selectedMembership">
            <option value="all">Alle</option>
            <option v-for="(membership, index) in memberStore.memberships" :key="index" :value="membership">
              {{ capitalizeFirstLetter(membership) }}
            </option>
          </select>
        </div>
        <div class="col-12 button-group col-lg-4">
          <button type="button" class="btn btn-green me-2" @click="modalStore.setModalVisible('addMember', true)">Nieuw lid aanmaken</button>
        </div>
        <div class="col-12 mt-3">
          <div class="row g-2" v-if="locationStore.selectedLocation?.members">
            <div 
              v-for="member in locationStore.filteredMembers" 
              :key="member.id" class="col-12 col-md-6 col-lg-4">
              <div class="card" style="min-height: 100%;" @click="memberStore.selectMember(member, true)">
                <div class="card-body">
                  <span class="badge" style="position:absolute; top:5px;right:5px;">ID: {{ member.id }}</span>
                  <!-- <span class="label" style="position:absolute; top:3px;right:5px;"><small class="text-muted">ID: {{ member.id }}</small></span> -->
                  <h5 class="card-title mb-0 fw-bold">{{ memberStore.fullName(member) }}</h5>
                  <span class="d-block"><strong>E-mail:</strong> {{ member.email }}</span>
                  <span class="d-block"><strong>Abonnement:</strong> {{ member.usertype }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <ModalMemberSelect></ModalMemberSelect> -->


    <Modal :id="'addMember'" title="" v-if="modalStore.isModalVisible('addMember')">
      <div class="row g-3">

          <div class="col-md-6">
            <div class="form-floating">
              <input v-model="memberStore.newMember.firstname" type="text" class="form-control" id="memberFirstname" placeholder="John">
              <label for="memberFirstname">Voornaam</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating">
              <input v-model="memberStore.newMember.lastname" type="text" class="form-control" id="memberLastname" placeholder="Doe">
              <label for="memberLastname">Achternaam</label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating">
              <input v-model="memberStore.newMember.email" type="text" class="form-control" id="memberEmail" placeholder="example@example.com">
              <label for="memberEmail">E-mailadres</label>
            </div>
          </div>
          <!-- <div class="col-12">
            <div class="form-floating">
              <input v-model="memberStore.newMember.phone" type="text" class="form-control" id="memberPhone" placeholder="07012341234">
              <label for="memberPhone">Telefoonnummer</label>
            </div>
          </div> -->
          <div class="col-12 mt-4">
            <h5 class="fw-bold">Abonnement</h5>
            <select class="form-select" id="selectedMembershipMemberships" v-model="memberStore.newMember.usertype">
              <option v-for="(membership, index) in memberStore.memberships" :key="index" :value="membership">
                {{ capitalizeFirstLetter(membership) }}
              </option>
            </select>
          </div>
          <div class="col-12 mt-4" v-if="memberStore.newMember.usertype == 'duo'">
            <h5 class="fw-bold">Duo partner</h5>
            <!-- <input class="form-control" type="search" v-model="memberStore.duoSearch" placeholder="Zoek lid.."> -->
            <select class="form-select" id="selectedMember" v-model="memberStore.newMember.duo">
              <option value="" disabled>Kies een member</option>
              <option v-for="(duo, index) in locationStore.filteredDuoMembers" :key="index" :value="duo.id">
                {{ memberStore.fullName(duo) }}
              </option>
            </select>
          </div>

          
        </div>
        <div class="row mt-3">
          <div class="col-12">
            
            <button  :disabled="!memberStore.isNewMemberValid" class="btn btn-primary w-100" @click="memberStore.updateMember(memberStore.newMember)">Opslaan</button>
          </div>
        </div>
      
    </Modal>

    <Modal :id="'removeEvent'" title="" v-if="modalStore.isModalVisible('removeEvent')">
      <div>
        <div class="row g-3">
          <div class="col">
            <span class="time h4 d-block">{{ capitalizeFirstLetter(moment(eventStore.eventInfo.event.start).format('dddd, D MMMM, YYYY')) }}</span>
            <span class="timeslot d-block">
              Tijdslot: {{  moment(eventStore.eventInfo.event.start).format('LT') }} - {{ moment(eventStore.eventInfo.event.end).format('LT') }}
            </span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 button-group">
            <button type="button" class="btn btn-red-outline me-2" @click="removeEvent">Afzeggen</button>
          </div>
        </div>
      </div>
  </Modal>
  </div>
</template>

<style lang="scss">
// .training-overview {
//   .training {
//     display: flex;
//     align-items: center;
//     background: white;
//     border-radius: 8px;
//     padding: 5px 5px 5px 15px;
//     box-shadow: 0 0 8px 0 rgba(50,183,104,0.16);
//     .date {
//       flex: 1;
//       font-size: 0.95em;
//       font-weight: 600;
//     }

//   }
//  }
</style>