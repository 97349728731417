<script setup>
import { useRoute } from 'vue-router';
import { Form, Field, useResetForm } from 'vee-validate';
import * as Yup from 'yup';

import { router } from '../router';
import { useAuthStore } from '../store';

const route = useRoute();
const authStore = useAuthStore();


import { onMounted, ref } from 'vue';  // Import ref from Vue
let token = ref('');  // Declare token as a ref
let showPassword = ref(false);
let loginError = ref("");
let apiError = ref(""); // New ref for API errors

const togglePasswordVisibility = () => {
    showPassword.value = !showPassword.value;
};

// redirect to home if already logged in
if (authStore.user) {
    router.push('/');
}

onMounted(() => {
    // Save the token from the query parameters
    token.value = route.query.token;
    // if (!token.value) {
    //     router.push('/');
    // }
    authStore.verifyToken(token.value)
        .then((result) => {
            if (!result) {
                loginError.value = "Token is niet meer geldig.";
                // router.push('/');
            } else {
                loginError.value = "";
            }
        });
});

const schema = Yup.object().shape({
    password: Yup.string()
        .required('Wachtwoord is verplicht')
        .min(8, 'Wachtwoord moet minimaal 8 tekens lang zijn')
        .matches(/[a-z]/, 'Wachtwoord moet ten minste één kleine letter bevatten')
        .matches(/[A-Z]/, 'Wachtwoord moet ten minste één hoofdletter bevatten')
        .matches(/\d/, 'Wachtwoord moet ten minste één nummer bevatten')
        .matches(/\W/, 'Wachtwoord moet ten minste één speciaal teken bevatten'),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Wachtwoorden komen niet overeen')
        .required('Wachtwoord bevestiging is verplicht')
});

function onSubmit(values, { setErrors, resetForm }) {
    return authStore.updatePassword(token.value, values.password)
        .then((result) => {
            console.log(result);
            if (result.success) {
                console.log('OK');
                // authStore.loginMessage = "Password changed!";
                resetForm();
                router.push({ path: '/login', query: { message: "Password is aangepast!" } });
            } else {
                console.log('ERROR');
                console.log(result.message);
                apiError.value = result.message;
                resetForm();
            }
        })
        .catch(error => {
            console.log("ERROR");
            apiError.value = result.message;
            resetForm();
        });
}
</script>

<template>
    <div class="w-100 text-center circle" style="max-width: 380px; margin:0 auto; padding: 30px; border-radius: 20px;">
        <span class="subtitle">Studio Tijd</span>
        <h2 class="mb-3">Nieuw wachtwoord instellen</h2>
        <span v-if="loginError" class="d-block alert alert-danger">
            {{ loginError }}
            <RouterLink to="/password-reset" v-slot="{ href, navigate, isActive, isExactActive }" custom>
                <a :href="href" style="font-weight:bold; display:block;" @click="navigate">Vraag opnieuw een wachtwoord reset aan</a>
            </RouterLink>
        </span>
        <ul class="password-rules alert alert-info">
            <li><strong>Wachtwoord regels</strong></li>
            <li>Minimaal 8 tekens lang zijn</li>
            <li>Ten minste één kleine letter bevatten</li>
            <li>Ten minste één hoofdletter bevatten</li>
            <li>Ten minste één nummer bevatten</li>
            <li>Ten minste één speciaal teken bevatten</li>
        </ul>
        <!-- <img class="login-img" src="@/assets/login-image.svg" alt=""> -->
        <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors, isSubmitting }">
            <div class="mb-3 text-left">
                <label class="form-label">Wachtwoord</label>
                <Field name="password" :type="showPassword ? 'text' : 'password'" class="form-control pwsd"
                    :class="{ 'is-invalid': errors.password }" />
                <span @click.prevent="togglePasswordVisibility" class="toggleShow">
                    <font-awesome-icon icon="eye" v-if="!showPassword"></font-awesome-icon>
                    <font-awesome-icon icon="eye-slash" v-else></font-awesome-icon>
                </span>
                <div class="invalid-feedback">{{ errors.password }}</div>
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Wachtwoord bevestiging</label>
                <Field name="password_confirmation" :type="showPassword ? 'text' : 'password'" class="form-control pwsd"
                    :class="{ 'is-invalid': errors.password_confirmation }" />
                <span @click.prevent="togglePasswordVisibility" class="toggleShow">
                    <font-awesome-icon icon="eye" v-if="!showPassword"></font-awesome-icon>
                    <font-awesome-icon icon="eye-slash" v-else></font-awesome-icon>
                </span>
                <div class="invalid-feedback">{{ errors.password_confirmation }}</div>
            </div>
            <div class="mb-3 text-left">
                <button class="btn btn-primary" :disabled="isSubmitting">
                    <span v-show="isSubmitting" class="spinner-border spinner-border-sm me-1"></span>
                    Wachtwoord opslaan
                </button>
            </div>
            <div class="mb-3 text-right">
                <router-link to="/login" v-slot="{ href, navigate, isActive, isExactActive }" custom>
                    <a :href="href" @click="navigate">Terug naar inloggen</a>
                </router-link>
            </div>
            <div v-if="apiError" class="alert alert-danger mt-3 mb-0">{{ apiError }}</div>
        </Form>
    </div>
</template>

<style scoped lang="scss">
h2 {
    font-weight: bold;
    color: var(--color-green);
}

.login-img {
    margin: 30px 0;
}

.text-left {
    text-align: left;
}

.form-label {
    color: #9CA3AF;
    margin-bottom: 0.15rem;
    padding-left: 0.5rem;
}

input {
    border-radius: 12px;
    border: 2px solid rgba(50, 183, 104, 0.16);
}

ul.password-rules {
    text-align: left;
    margin: 0 0 20px 0;

    li {
        list-style: none;
    }
}

input.pswd {
    padding-right: 40px;
}

.toggleShow {
    position: absolute;
    background: transparent;
    top: 25px;
    right: 7px;
    border: 0;
    outline: 0;
    height: 30px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn.btn-primary {
    background: var(--color-green);
    color: white;
    display: block;
    width: 100%;
    border-radius: 12px;
    padding: 10px 10px;
    border-color: var(--color-green);
}

.circle:after {
    position: fixed;
    z-index: -1;
    content: '';
    width: 75vh;
    height: 75vh;
    right: -45vh;
    top: calc(50% - 37.5vh);
    background: #EDFDF5;
    border-radius: 50vh;
}
</style>