// src/composables/useEnhancedInterval.js
import { onMounted, onUnmounted } from 'vue';
import { useAuthStore } from '../store/AuthStore'; // Adjust the import path as needed

export function useEnhancedInterval() {
  const authStore = useAuthStore();
  let intervalId = null;

  const fetchData = () => {
    console.log('Fetching user data');
    authStore.refetchUser();
  };

  const startInterval = () => {
    if (intervalId) clearInterval(intervalId);
    intervalId = setInterval(() => {
      if (!document.hidden) {
        fetchData();
      }
    }, 10000);
  };

  const handleVisibilityChange = () => {
    if (document.hidden) {
      clearInterval(intervalId);
    } else {
      // Fetch data immediately when tab becomes active
      fetchData();
      // Then start the interval
      startInterval();
    }
  };

  onMounted(() => {
    if (authStore.user && !['admin', 'support', 'trainer'].includes(authStore.user.usertype)) {
      console.log('Setting up enhanced interval for user refetch');
      // Fetch data immediately on mount
      fetchData();
      // Start the interval
      startInterval();
      document.addEventListener('visibilitychange', handleVisibilityChange);
    }
  });

  onUnmounted(() => {
    if (intervalId) clearInterval(intervalId);
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  });
}