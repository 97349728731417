<script setup>
import { useRoute } from 'vue-router';
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';
import { ref, onMounted, onBeforeUnmount } from 'vue';

import { useAuthStore } from '../store';
const route = useRoute();

const authStore = useAuthStore();
authStore.loginError = "";

let showPassword = ref(false);
let loginMessage = ref("");
const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
};

let loginError = ref("");

const schema = Yup.object().shape({
  username: Yup.string().email('Ongeldig e-mailadres').required('E-mailadres is verplicht'),
  password: Yup.string().required('Wachtwoord is verplicht')
});

function onSubmit(values, { setErrors }) {
  const { username, password } = values;
  authStore.loginMessage = "";
  authStore.loginError = "";
  return authStore.login(username, password)
    .then(() => {
      // redirect to previous url or default to home page
      authStore.loginMessage = "";

      loginError.value = "";
      // router.push(route.query.returnUrl || '/');
    })
    .catch(error => {
      loginError.value = "Inloggegevens zijn niet correct";
      // setErrors({ apiError: error });
    });
}
onMounted(() => {
  // Check if there's a message in the route query
  console.log(route.query.message);
  if (route.query.message) {
    loginMessage.value = route.query.message;
    // Clear the message from the URL
    history.replaceState(null, '', route.path);
  } else {
    // If no message in query, use the one from authStore
    loginMessage.value = authStore.loginMessage;
  }
  authStore.loginError = "";
});
onBeforeUnmount(() => {
  loginMessage.value = "";
  loginError.value = "";
});

</script>

<template>
  <div class="w-100 text-center circle" style="max-width: 380px; margin:0 auto; padding: 30px; border-radius: 20px;">
    <span class="subtitle">Fit20</span>
    <h2>Studio Tijd</h2>
    <img class="login-img" src="@/assets/login-image.svg" alt="">
    <span v-if="loginMessage" class="d-block alert alert-success">{{ loginMessage }}</span>
    <span v-if="authStore.loginError" class="d-block alert alert-danger">{{ authStore.loginError }}</span>
    <span v-if="loginError" class="d-block alert alert-danger">{{ loginError }}</span>
    <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors, isSubmitting }">
      <div class="mb-3 text-left">
        <label class="form-label">E-mailadres</label>
        <Field name="username" type="email" class="form-control" :class="{ 'is-invalid': errors.username }" />
        <div class="invalid-feedback">{{ errors.username }}</div>
      </div>
      <div class="mb-3 text-left">
        <label class="form-label">Wachtwoord</label>
        <Field name="password" :type="showPassword ? 'text' : 'password'" class="form-control pswd"
          :class="{ 'is-invalid': errors.password }" />
        <span @click.prevent="togglePasswordVisibility" class="toggleShow">
          <font-awesome-icon icon="eye" v-if="!showPassword"></font-awesome-icon>
          <font-awesome-icon icon="eye-slash" v-else></font-awesome-icon>
        </span>
        <div class="invalid-feedback">{{ errors.password }}</div>
      </div>

      <div class="mb-3 text-left">
        <button class="btn btn-primary" :disabled="isSubmitting">
          <span v-show="isSubmitting" class="spinner-border spinner-border-sm me-1"></span>
          Login
        </button>
      </div>
      <div class="mb-3 text-right">
        <router-link to="/password-reset" v-slot="{ href, navigate, isActive, isExactActive }" custom>
          <a :href="href" @click="navigate">Wachtwoord vergeten?</a>
        </router-link>
      </div>
      <div v-if="errors.apiError" class="alert alert-danger mt-3 mb-0">{{ errors.apiError }}</div>
    </Form>
  </div>
</template>

<style scoped>
h2 {
  font-weight: bold;
  color: var(--color-green);
}

.login-img {
  margin: 30px 0;
}

.text-left {
  text-align: left;
}

.form-label {
  color: #9CA3AF;
  margin-bottom: 0.15rem;
  padding-left: 0.5rem;
}

input {
  border-radius: 12px;
  border: 2px solid rgba(50, 183, 104, 0.16);
}

input.pswd {
  padding-right: 40px;
}

.toggleShow {
  position: absolute;
  background: transparent;
  top: 25px;
  right: 7px;
  border: 0;
  outline: 0;
  height: 30px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn.btn-primary {
  background: var(--color-green);
  color: white;
  display: block;
  width: 100%;
  border-radius: 12px;
  padding: 10px 10px;
  border-color: var(--color-green);
}

.circle:after {
  position: fixed;
  z-index: -1;
  content: '';
  width: 75vh;
  height: 75vh;
  right: -45vh;
  top: calc(50% - 37.5vh);
  background: #EDFDF5;
  border-radius: 50vh;
}
</style>