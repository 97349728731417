<script setup>
import { useRoute } from 'vue-router';
import { Form, Field, useResetForm } from 'vee-validate';
import * as Yup from 'yup';

import { router } from '../router';
import { useAuthStore } from '../store';

const route = useRoute();
const authStore = useAuthStore();

import { ref } from 'vue';  // Import ref from Vue
let resetOk = ref(false);  // Make resetOk a ref
let emailDoesntExist = ref(false);

// redirect to home if already logged in
if (authStore.user) {
    router.push('/');
}

const schema = Yup.object().shape({
    email: Yup.string().required('E-mailadres is verplicht'),
});

function onSubmit(values, { setErrors, resetForm }) {
    // const { email } = values;
    console.log(values);
    resetOk.value = false;
    emailDoesntExist.value = false;
    return authStore.resetPassword(values.email)
        .then(() => {
            console.log('OK');
            resetOk.value = true; 
            resetForm();
        })
        .catch(error => {
            console.log("ERROR");
            resetOk.value = false;   // Set resetOk to false when there's an error
            emailDoesntExist.value = true;
            setErrors({ apiError: error.message })
            resetForm();

            // actions.setFieldValue('email', '');
        })
}
</script>

<template>
    <div class="w-100 text-center circle" style="max-width: 380px; margin:0 auto; padding: 30px; border-radius: 20px;">
        <span class="subtitle">Fit20</span>
        <h2>Studio Tijd</h2>
        <img class="login-img" src="@/assets/login-image.svg" alt="">
        <div class="alert alert-info" v-if="resetOk">Er is een e-mail verstuurd met een link om je wachtwoord in te stellen.<br><strong>Heb je geen e-mail ontvangen? Controleer dan je spamfolder. Nog steeds geen e-mail? Neem dan contact op met de eigenaar van de studio.</strong></div>
        <div class="alert alert-warning" v-if="emailDoesntExist">E-mailadres bestaat niet in ons systeem. Het kan zijn dat er een ander email bij ons bekend is, stuur ons een mail en we zoeken het uit</div>
        <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors, isSubmitting }">
            <div class="mb-3 text-left">
                <label class="form-label">E-mailadres</label>
                <Field name="email" type="email" class="form-control" :class="{ 'is-invalid': errors.email }" />
                <div class="invalid-feedback">{{errors.email}}</div>
            </div>            
            <div class="mb-3 text-left">
                <button class="btn btn-primary" :disabled="isSubmitting">
                    <span v-show="isSubmitting" class="spinner-border spinner-border-sm me-1"></span>
                    Account activatie aanvragen
                </button>
            </div>
            <!-- <div class="mb-3 text-right">
                <router-link
                    to="/login"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                    custom
                >
                    <a :href="href" @click="navigate">Terug naar inloggen</a>
                </router-link>
            </div> -->
            <div v-if="errors.apiError" class="alert alert-danger mt-3 mb-0">{{errors.apiError}}</div>
        </Form>
    </div>
</template>

<style scoped>
    h2 {
        font-weight: bold;
        color: var(--color-green);
    }
    .login-img {
        margin: 30px 0;
    }
    .text-left {
        text-align: left;
    }
    .form-label {
        color: #9CA3AF;
        margin-bottom: 0.15rem;
        padding-left: 0.5rem;
    }
    input {
        border-radius: 12px;
        border: 2px solid rgba(50, 183, 104, 0.16);
    }
    .btn.btn-primary {
        background: var(--color-green);
        color: white;
        display: block;
        width: 100%;
        border-radius: 12px;
        padding: 10px 10px;
        border-color: var(--color-green);
    }
    .circle:after {
        position: fixed;
        z-index: -1;
        content: '';
        width: 75vh;
        height: 75vh;
        right: -45vh;
        top: calc(50% - 37.5vh);
        background: #EDFDF5;
        border-radius: 50vh;
    }
</style>