import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import App from "./App.vue";
import router from "./router";
import * as Sentry from "@sentry/vue";

import "bootstrap";
// import '@fortawesome/fontawesome-pro/css/all.css'

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import { createDeviceDetector } from "next-vue-device-detector";
export const device = createDeviceDetector();

// Localization
import { defineRule, configure } from "vee-validate";
defineRule("required", (value) => {
  if (!value || !value.length) {
    return "This field is verplicht";
  }
  return true;
});
defineRule("email", (value) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }
  // Check if email
  if (!/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/.test(value)) {
    return "This field must be a valid email NL";
  }
  return true;
});

import moment from "moment";
// Set the moment locale
moment.locale("nl");

import { isDateInRange } from "./helpers/functions";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUser,
  faUserGroup,
  faUserGroupSimple,
  faUsers,
  faGridHorizontal,
  faBuilding,
  faHome,
  faCalendarAlt,
  faCalendarCirclePlus,
  faTimes,
  faClock,
  faRefresh,
  faCircle,
  faPlus,
  faNoteMedical,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faUser as fasUser,
  faUsers as fasUsers,
  faBuilding as fasBuilding,
  faUserPlus,
  faHome as fasHome,
  faCalendarStar as fasCalendarStar,
  faChevronDown,
  faChevronUp,
  faEye,
  faEyeSlash,
  faBars,
  faCheck,
  faBarsFilter,
  faCircleCheck,
  faTimes as fasTimes,
  faPlus as fasPlus,
  faNoteMedical as fasNoteMedical,
  faUserGroupSimple as fasUserGroupSimple,
  faNoteSticky,
  faInfoCircle
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(
  faUser,
  fasUser,
  faUserGroup,
  faUsers,
  fasUsers,
  faUserPlus,
  fasCalendarStar,
  faGridHorizontal,
  faBuilding,
  fasBuilding,
  faHome,
  fasHome,
  fasTimes,
  faCalendarAlt,
  faCalendarCirclePlus,
  faTimes,
  faClock,
  faRefresh,
  faChevronDown,
  faChevronUp,
  faEye,
  faEyeSlash,
  faBars,
  faCheck,
  faBarsFilter,
  faCircle,
  faCircleCheck,
  faNoteMedical,
  fasNoteMedical,
  faNoteSticky,
  faPlus,
  fasPlus,
  faUserGroupSimple,
  fasUserGroupSimple,
  faInfoCircle
);

import { useAuthStore } from "./store/AuthStore";
startApp();

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function initSentry(app) {
  Sentry.init({
    app,
    dsn: "https://e214ea260c1f8d32cb667b6cf62c55c4@o4507346150883328.ingest.de.sentry.io/4507656952873040",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/staging.studiotijd\.nl/,
      /^https:\/\/.studiotijd\.nl/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// async start function to enable waiting for refresh token call
async function startApp() {
  const app = createApp(App);
  const production = import.meta.env.VITE_PRODUCTION === "true";

  // Initialize Sentry
  initSentry(app);
  app.use(createPinia().use(piniaPluginPersistedstate));
  app.use(router);
  app.use(device);
  app.directive("resize", {
    beforeMount(el, binding) {
      // const authStore = useAuthStore();
      // if (authStore.user.usertype === 'support') return; // Exit early if user is 'support'
      // const onResizeCallback = binding.value;
      // // Call onResizeCallback on page load
      // onResizeCallback({ width: window.innerWidth, height: window.innerHeight });
      // window.addEventListener('resize', () => {
      //   const width = document.documentElement.clientWidth;
      //   const height = document.documentElement.clientHeight;
      //   onResizeCallback({ width, height });
      // });
    },
  });
  app.component("font-awesome-icon", FontAwesomeIcon);
  app.component("v-select", vSelect);
  app.config.globalProperties.$moment = moment;
  app.config.globalProperties.$capitalizeFirstLetter = capitalizeFirstLetter;
  app.config.globalProperties.$isDateInRange = isDateInRange;

  // // Vue devtool during production for TESTING
  // app.config.devtools = true;
  console.log(
    "%c 🫡 Creating webapplication ",
    "color: #fff; background: #32B768; font-size: 12px; border-radius: 5px; padding: 6px 3px; margin: 10px 0 5px;"
  );
  console.log(
    `%c Mode: ${import.meta.env.MODE}`,
    "color: #fff; background: #2c3e50; font-size: 10px; border-radius: 5px; padding: 6px 3px; margin: 0 0 10px;"
  );
  // console.log(import.meta.env.BASE_URL);

  // Attempt to auto refresh token before startup
  try {
    const authStore = useAuthStore();
    // await authStore.refreshToken();
  } catch (error) {
    // Catch error to start app on success or failure
    console.error("Error refreshing token:", error);
    // You might want to log this error to Sentry
    // Sentry.captureException(error);
  }

  app.mount("#app");
}
