<script setup>
import { useLocationStore } from '../store/LocationStore'
const store = useLocationStore()

import { onMounted } from 'vue'

// onMounted(() => {
//   console.log('mounted');
//   store.fetchLocations()
// })

import { storeToRefs } from 'pinia';

import { useAuthStore } from '../store';

const authStore = useAuthStore();
const { user: authUser } = storeToRefs(authStore);

</script>

<template>
  <div>
    <div class="px-8 header">
      <h1 class="me-4">
        <span class="text-muted ms-2">Hallo,&nbsp;</span>{{authUser?.firstname}} {{authUser?.lastname}}!
      </h1>
    </div>
    <div class="px-8 my-4">
      <div class="row">
        <div v-for="location in store.locations" :key="location.id" class="col-12 mb-4">
          <div class="card">
            <div class="card-body">
              <!-- <span class="badge bg-dark mb-1">Locatie</span> -->
              <h4 class="card-title">{{ location.name }}</h4>
              <ul class="list-group">
              <router-link
              :to="{ name: 'calendar', params: { id: location.id } }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              custom
              >
                <li class="list-group-item" :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"><a :href="href" @click="navigate">
                  <font-awesome-icon icon="far fa-calendar-alt" />
                  Kalender 
                </a></li>
              </router-link>
              <router-link
              :to="{ name: 'recurring', params: { id: location.id } }"
              v-slot="{ href, navigate, isActive, isExactActive }"
              custom
              >
                <li class="list-group-item" :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"><a :href="href" @click="navigate">
                  <font-awesome-icon icon="far fa-calendar-alt" />
                  Vaste Trainingen 
                </a></li>
              </router-link>
              <router-link
                :to="{ name: 'members', params: { id: location.id } }"
                v-slot="{ href, navigate, isActive, isExactActive }"
                custom
              >
                <li class="list-group-item" :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"><a :href="href" @click="navigate">
                  <font-awesome-icon icon="fas fa-users" />
                  Leden
                  </a></li>
              </router-link>
              <!-- <router-link
                :to="{ name: 'settings', params: { id: location.id } }"
                v-slot="{ href, navigate, isActive, isExactActive }"
                custom
              >
                <li class="list-group-item" :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"><a :href="href" @click="navigate">
                  <font-awesome-icon icon="far fa-clock" />
                  Openingstijden</a></li>
              </router-link>
              <router-link
                :to="{ name: 'profile', params: { id: location.id } }"
                v-slot="{ href, navigate, isActive, isExactActive }"
                custom
              >
                <li class="list-group-item" :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']"><a :href="href" @click="navigate">
                  <font-awesome-icon icon="far fa-building" />
                  Contactinfo</a></li>
              </router-link> -->
            </ul>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
