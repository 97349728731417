<!-- Modal__MemberSelect.vue -->
<template>
   <Modal :id="'selectMember'" :title="memberStore.fullName(memberStore.selectedMember)" v-if="modalStore.isModalVisible('selectMember')" storeName="memberStore" functionName="deselectMember">
      <div>
         <!-- Navigation for tabs -->
        <div class="row">
          <div class="col-12">
            <ul class="nav nav-tabs mb-3">
              <li class="nav-item">
                <a class="nav-link" :class="{ active: currentTab === 'Tab1' }" @click="currentTab = 'Tab1'">Gegevens</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{ active: currentTab === 'Tab2' }" @click="currentTab = 'Tab2'">Trainingen</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{ active: currentTab === 'Tab3' }" @click="currentTab = 'Tab3'">Geschiedenis</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{ active: currentTab === 'Tab4' }" @click="currentTab = 'Tab4'">Notitie's</a>
              </li>
            </ul>
          </div>
        </div>
        <div v-show="currentTab == 'Tab1'" class="row g-3">
          <div class="col-12">
            <span class="badge bg-secondary">ID: {{memberStore.selectedMember.id}}</span>
          </div>
          <div class="col-md-6">
            <div class="form-floating">
              <input v-model="memberStore.selectedMember.firstname" type="text" class="form-control" id="memberFirstname" placeholder="John">
              <label for="memberFirstname">Voornaam</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating">
              <input v-model="memberStore.selectedMember.lastname" type="text" class="form-control" id="memberLastname" placeholder="Doe">
              <label for="memberLastname">Achternaam</label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating">
              <input v-model="memberStore.selectedMember.email" type="text" class="form-control" id="memberEmail" placeholder="example@example.com">
              <label for="memberEmail">E-mailadres</label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating">
              <input v-model="memberStore.selectedMember.phone" type="text" class="form-control" id="memberPhone" placeholder="07012341234">
              <label for="memberPhone">Telefoonnummer</label>
            </div>
          </div>
          <div class="col-12 mt-4">
            <h5 class="fw-bold">Abonnement</h5>
            <select class="form-select" id="selectedMembershipMemberships" v-model="memberStore.selectedMember.usertype">
              <option v-for="(membership, index) in memberStore.memberships" :key="index" :value="membership">
                {{ capitalizeFirstLetter(membership) }}
              </option>
            </select>
          </div>
          <div class="col-12 mt-4" v-if="memberStore.selectedMember.usertype == 'duo'">
            <h5 class="fw-bold">Duo partner</h5>
            <!-- <input class="form-control" type="search" v-model="memberStore.duoSearch" placeholder="Zoek lid.."> -->
            <select class="form-select" id="selectedMember" v-model="memberStore.selectedMember.duo">
              <option value="" disabled>Kies een member</option>
              <option v-for="(duo, index) in locationStore.filteredDuoMembers" :key="index" :value="duo.id">
                {{ memberStore.fullName(duo) }}
              </option>
            </select>
          </div>
          <div class="col-12 mt-4" v-if="memberStore.selectedMember.alt_usertype">
            <p>Duo partner staat inactief <span v-if="memberStore.selectedMember.alt_usertype_from">vanaf {{ memberStore.selectedMember.alt_usertype_from }}</span><span v-if="memberStore.selectedMember.alt_usertype_until"> tot {{ memberStore.selectedMember.alt_usertype_until }}</span></p>
            
            
          </div>
          <div class="col-4 mt-4">
            <h5 class="fw-bold">Inactief</h5>
            <div class="form-check">
              <input v-model="memberStore.selectedMember.inactive" :checked="memberStore.selectedMember.inactive" type="checkbox" class="form-check-input" id="memberInactive">
              <label class="form-check-label" for="memberInactive">Inactief?</label>
            </div>
          </div>
          <div v-if="memberStore.selectedMember.inactive" class="col-4 mt-4">
            <div class="form-floating">
              <input v-model="memberStore.selectedMember.inactive_from" type="date" class="form-control" id="memberInactivFrom">
              <label class="form-check-label" for="memberInactiveFrom">Vanaf</label>
            </div>
          </div>
          <div v-if="memberStore.selectedMember.inactive" class="col-4 mt-4">
            <div class="form-floating">
              <input v-model="memberStore.selectedMember.inactive_until" type="date" class="form-control" id="memberInactiveTill">
              <label class="form-check-label" for="memberInactiveTil">Tot</label>
            </div>
          </div>
          <div v-if="memberStore.selectedMember.inactive" class="mt-3">
            <div class="col-6"></div>
            <div class="col-6">
              <button class="btn btn-red btn-small w-100" @click="memberStore.deleteMember(memberStore.selectedMember.id)">Definitief verwijderen</button>
            </div>
          </div>
          <div class="col-12 mt-4">
            <h5 class="fw-bold">Notificaties</h5>
            <div class="form-check">
              <input v-model="memberStore.selectedMember.notify" type="checkbox" class="form-check-input" id="memberNotify" disabled>
              <label class="form-check-label" for="memberNotify">Heeft e-mail notificaties aan?</label>
            </div>
          </div>
        </div>
        <div v-show="currentTab == 'Tab2'" class="row g-3">
          <div class="training-overview">
            <div class="training" v-for="(timeslot, index) in memberStore.filteredMemberTimeslotsComing" :key="index">
              <span class="date" @click="handleEventClick(timeslot)">{{ capitalizeFirstLetter(moment(timeslot.start).format('ddd D MMMM YYYY')) }} - {{  moment(timeslot.start).format('LT') }} - {{ moment(timeslot.end).format('LT') }}</span>
              <span class="actions">
                <button @click="removeTimeslotFromMember(timeslot)" class="btn btn-red"><font-awesome-icon icon="far fa-times"/></button>
              </span>
            </div>
          </div>
        </div>
        <div v-show="currentTab == 'Tab3'" class="row g-3">
          <div class="training-overview">
            <div class="training mb-2" v-for="(timeslot, index) in memberStore.filteredMemberTimeslotsPast" :key="index">
              <span class="date" @click="handleEventClick(timeslot)">{{ capitalizeFirstLetter(moment(timeslot.start).format('ddd D MMMM YYYY')) }} - {{  moment(timeslot.start).format('LT') }} - {{ moment(timeslot.end).format('LT') }}</span>
              <!-- <span class="actions">
                <button @click="removeTimeslotFromMember(timeslot)" class="btn btn-red"><font-awesome-icon icon="far fa-times"/></button>
              </span> -->
            </div>
          </div>
        </div>
        <div v-show="currentTab == 'Tab4'" class="row g-3">
          <div class="training-overview">
            <div class="training mb-2" v-for="(note, index) in memberStore.selectedMember.notes" :key="index">
              <span class="date">{{ capitalizeFirstLetter(moment(note.date).format('DD-MM-YYYY HH:mm')) }}: {{ note.message }}</span>
              <span class="actions">
                <button @click="memberStore.removeNoteFromMember(index,memberStore.selectedMember)" class="btn btn-red"><font-awesome-icon icon="far fa-times"/></button>
              </span>
            </div>
            <input hidden type="text" :id="removeNote" v-model="memberStore.selectedMember.removeNotes"/>
            <div class="row mt-3">
              <div class="col-12">
                <textarea  class="form-control" :id="addNote" v-model="memberStore.selectedMember.addNote"></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-6">
            <button :disabled="!memberStore.isSelectedMemberChanged" class="btn btn-primary w-100" @click="memberStore.updateMember(memberStore.selectedMember)">Opslaan</button>
          </div>
          <div class="col-6">
            <button :disabled="memberStore.selectedMember.email !== memberStore.selectedMemberLoad.email" class="btn btn-red-outline w-100" @click="memberStore.sendPasswordResetEmail(memberStore.selectedMember.email)">Activatie email</button>
          </div>
        </div>
      </div>
    </Modal>
</template>

<script setup>
import Modal from './Modal.vue'; // Import the modal component
import { ref, computed, watch } from 'vue';
import { useModalStore } from '../store/ModalStore'
import { useLocationStore } from '../store/LocationStore'
import { useMemberStore } from '../store/MemberStore'

import moment from 'moment'
import 'moment/dist/locale/nl'
import { capitalizeFirstLetter } from '../helpers/functions'

const modalStore = useModalStore();
const memberStore = useMemberStore();
const locationStore = useLocationStore();

let currentTab = ref('Tab1')
</script>
