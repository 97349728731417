<script setup>
import { watch, computed } from 'vue';
const authStore = useAuthStore()
const memberStore = useMemberStore()
const locationStore = useLocationStore()
const eventStore = useEventStore()
const modalStore = useModalStore()
const fullCalendar = ref(null)
const showMoveModal = ref(false); // Define showMoveModal here

watch(() => authStore.refreshCalendar, (newValue, oldValue) => {
  if (fullCalendar.value) {
    fullCalendar.value.getApi().refetchEvents();
  }
});

const openMoveModal = () => {
  showMoveModal.value = true;
  modalStore.setModalVisible('moveTraining', true);
};

const closeMoveModal = () => {
  showMoveModal.value = false;
  modalStore.setModalVisible('moveTraining', false);
};

</script>

<script>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '../store/AuthStore'
import { useMemberStore } from '../store/MemberStore'
import { useLocationStore } from '../store/LocationStore'
import { useEventStore } from '../store/EventStore'
import { useModalStore } from '../store/ModalStore'
import Modal from '../components/Modal.vue'

import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import bootstrap5Plugin from '@fullcalendar/bootstrap5'
import nlLocale from '@fullcalendar/core/locales/nl'
import 'add-to-calendar-button'
import { atcb_action } from "add-to-calendar-button"
import { format, parseISO, addWeeks, startOfISOWeek, add, isPast } from 'date-fns'
import { nl } from 'date-fns/locale'
import moment from 'moment'
import 'moment/dist/locale/nl'
import { statusToString, getFirstDayOfWeek, getLastDayOfWeek, formattedRecurringDate, capitalizeFirstLetter, getCurrentWeek, getCurrentYear, getNextYear, isAtLeast24HoursAway, isAtLeast6HoursAway, isAtLeast1HourAway, getWeekFromDate } from '../helpers/functions'

let headerToolbarDesktop = {
  left: 'today prev,next',
  center: 'title',
  right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
}
let headerToolbarMobile = {
  left: 'prev',
  center: 'title',
  right: 'next'
}



export default {

  components: {
    // Modal,
    FullCalendar // make the <FullCalendar> tag available
  },

  data() {
    return {
      isUserLoaded: false,
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
      showBoxHeader: true,
      SevenDayNav: [],
      allEventsRendered: false,
      bookingInCurrentWeek: null,
      activeDay: null,
      menuDropdown: false,
      bookingResponses: [],
      currentDateIsInAltUsertypeRange: false,
      calendarConfig: {
        name: "fit20 Training",
        description: "",
        timeZone: "Europe/Amsterdam",
        location: "fit20 ",
        options: ['Apple', 'Google', 'iCal', 'Outlook.com'],
      },
      calendarOptions: {
        locale: nlLocale,
        timeZone: 'Europe/Amsterdam',
        plugins: [
          bootstrap5Plugin,
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
          interactionPlugin // needed for dateClick
        ],
        themeSystem: 'bootstrap5',
        headerToolbar: headerToolbarDesktop,
        // initialView: initialView,
        initialView: 'listDay',
        slotDuration: {
          "minutes": 10
        },
        slotMinTime: '06:30:00',  // Start at 6:30 AM
        slotMaxTime: '22:00:00',  // End at 10 PM
        firstDay: 1, // Monday
        dayMaxEvents: true, // allow "more" link when too many events
        editable: false,
        selectable: true,
        weekNumbers: true,
        dayMaxEvents: true,
        weekends: true,
        buttonText: {
          today: 'Vandaag',
          month: 'Maand',
          week: 'Week',
          day: 'Dag',
          list: 'Lijst',
          prev: 'Vorige',
          next: 'Volgende'
        },
        lazyFetching: false,
        eventSources: [
          {
            url: import.meta.env.VITE_API_URL + 'load-timeslots.php',
            method: 'GET',
            extraParams: this.locationParams,
            cache: true,
          }
        ],
        eventsSet: (events) => {
          if (events.length > 0) {
            this.manageBookingSlotAvailability()
          }
        },
        events: null,
        eventClick: this.handleEventClick,
        eventDidMount: this.handleEventDidMount,
        eventWillUnmount: this.handleEventWillUnmount,
      },
      loadedEvents: []
    }
  },
  methods: {

    openMoveModal() {
      const modalStore = useModalStore();
      showMoveModal.value = true;
      modalStore.setModalVisible('moveTraining', true);
    },

    closeMoveModal(){
      const modalStore = useModalStore();
      showMoveModal.value = false;
      modalStore.setModalVisible('moveTraining', false);
    },

    locationParams() {
      const authStore = useAuthStore();
      const locationStore = useLocationStore();
      if (authStore.userLevel == 2 || authStore.userLevel == 3) {
        return {
          location: Number(locationStore.selectedLocation.id)
        }
      } else {
        return {
          location: Number(authStore.user.location_id)
        }
      }
    },
    userHasCreditForWeek(weekNumber) {
      // console.log(weekNumber);
      try {
        const authStore = useAuthStore();
        var nextWeek = parseInt(weekNumber, 10);
        nextWeek = nextWeek - 1;
        return authStore.user.credits.available?.includes(weekNumber) ||
          authStore.user.credits.available?.includes(nextWeek.toString());
      } catch (error) {
        console.error("Error in userHasCreditForWeek:", error);
        return false;
      }
    },
    userUsedCreditForWeek(weekNumber) {

      const authStore = useAuthStore();
      var week = parseInt(weekNumber, 10);
      //alert(week);
      if (authStore.user.credits.used?.includes(week) || authStore.user.credits.used?.includes(week.toString())) {
        return true;
      }

      return false;


    },
    manageBookingSlotAvailability() {
      console.log('Function ', 'manageBookingSlotAvailability');


      const currentDate = this.$refs.fullCalendar.getApi().getDate();
      const currentDateFormatted = format(currentDate, 'yyyy-MM-dd');
      const currentWeekNumber = getCurrentWeek(currentDate).toString();
      const currentYear = getCurrentYear(currentDate);
      const firstDayOfWeek = getFirstDayOfWeek(currentYear, currentWeekNumber);
      const lastDayOfWeek = getLastDayOfWeek(currentYear, currentWeekNumber);
      const isInRange = this.$isDateInRange(currentDate, firstDayOfWeek, lastDayOfWeek);
      const bookedSlot = document.querySelector('.self-booked');
      const todaysWeekNumber = getCurrentWeek(new Date());

      const hasCreditForWeek = this.userHasCreditForWeek(currentWeekNumber);
      const authStore = useAuthStore();

      authStore.refetchUser();

      var isInactive = false;

      let slots, intros, weekIsUsed, element, alert;

      if (authStore.user && Object.keys(authStore.user).length > 0) {
        weekIsUsed = authStore.user.credits.used?.includes(currentWeekNumber);
        slots = document.querySelectorAll('.fc-timegrid-event-harness a:not(.self-booked)');
        intros = document.querySelectorAll('.fc-timegrid-event-harness a.status--7');
        element = document.querySelector('.fc-scroller.fc-scroller-liquid-absolute');
        alert = document.querySelector('.fc-scroller.fc-scroller-liquid-absolute span.disabledWeek');
        intros.forEach(intro => {
          intro.classList.add('disabled');
        });
      } else {
        weekIsUsed = authStore.user.credits.used?.includes(currentWeekNumber);
        slots = document.querySelectorAll('tr.fc-event:not(.self-booked)');
        element = document.querySelector('.fc-scroller.fc-scroller-liquid');
        alert = document.querySelector('.fc-scroller.fc-scroller-liquid span.disabledWeek');
      }

      if (alert) {
        alert.remove();
      }

      if (authStore.inactiveDates.includes(currentDateFormatted)) {
        isInactive = true;
        const spanInactive = document.createElement('span');
        spanInactive.textContent = 'Account staat op inactief voor deze datum';
        spanInactive.className = 'alert alert-warning disabledWeek';
        element.appendChild(spanInactive);
      }


      const isWeekBeyondRange = currentWeekNumber > todaysWeekNumber + 7;


      slots.forEach(event => {
        const disableCondition = weekIsUsed || (this.weekHasBooking() && !hasCreditForWeek) || isWeekBeyondRange || isInactive;
        const enableCondition = isInRange && hasCreditForWeek && !weekIsUsed;

        if (disableCondition) {
          event.classList.add('shift');
        } else if (enableCondition) {
          event.classList.remove('shift');
        }
        if (this.userUsedCreditForWeek(currentWeekNumber)) {
          event.classList.add('d-none');
          event.classList.add('disabled');
        }
      });
      const creditElement = document.querySelector('.creditUsed');
      if (creditElement) {
        creditElement.remove();
      }
      if (this.userUsedCreditForWeek(currentWeekNumber)) {
        const element = document.querySelector('.fc-scroller.fc-scroller-liquid');

        const span = document.createElement('span');
        span.textContent = 'Training is binnen 1 uur geannuleerd. Week niet beschikbaar om te boeken.';
        span.className = 'alert alert-warning disabledWeek creditUsed';
        element.appendChild(span);
      }
      // Alert if calendar is past 8 weeks
      if (isInRange && bookedSlot || this.weekHasBooking() || isWeekBeyondRange) {
        if (isWeekBeyondRange) {
          const span = document.createElement('span');
          span.textContent = 'Week nog niet beschikbaar om te boeken. Maximaal 8 weken vooruit boeken.';
          span.className = 'alert alert-warning disabledWeek';
          element.appendChild(span);
        }
      }
    },

    formatCurrentDate() {
      if (this.$refs.fullCalendar) {
        var date = this.$refs.fullCalendar.getApi().getDate();
        return format(date, 'd MMMM', { locale: nl });
      }
    },
    navDate(dateInput) {
      const dateStr = dateInput;
      const date = moment(dateStr);
      const formattedDate = [
        date.locale('nl').format('dd')[0].toUpperCase() + date.format('dd').slice(1),
        date.locale('nl').format('D')
      ];
      return formattedDate;
    },
    goToDayOfWeek(direction) {
      const currentDate = moment()
        .isoWeekYear(Number(this.$route.query.year))
        .isoWeek(Number(this.$route.query.week));
      let newDate;

      if (direction === 'prev') {
        newDate = currentDate.subtract(1, 'week');
      } else if (direction === 'next') {
        newDate = currentDate.add(1, 'week');
      }

      this.$router.push({
        path: '/calendar',
        query: {
          week: newDate.isoWeek(),
          year: newDate.isoWeekYear()
        }
      });
    },
    goToDate(date) {


      const formattedDate = format(new Date(date), 'yyyy-MM-dd');
      this.activeDay = formattedDate;
      if (this.$refs.fullCalendar) {
        return this.$refs.fullCalendar.getApi().gotoDate(formattedDate);
      }
    },
    weekHasBooking() {
      if (this.$refs.fullCalendar) {
        const authStore = useAuthStore();
        const memberStore = useMemberStore();
        const currentDate = this.$refs.fullCalendar.getApi().getDate();
        const currentWeekNumber = getCurrentWeek(currentDate);
        const currentYear = currentDate.getFullYear(); // Get current year

        if (authStore.user && Object.keys(authStore.user).length > 0) {
          var bookings = authStore.user.timeslots;
        } else {
          var bookings = authStore.user.timeslots;
        }

        this.bookingsInCurrentWeek = bookings.filter(booking => {
          const bookingDate = new Date(booking.start);
          const bookingWeek = getWeekFromDate(booking.start);
          const bookingYear = bookingDate.getFullYear(); // Get booking year

          // Compare both week and year
          return bookingWeek.toString() === currentWeekNumber.toString() && 
                bookingYear === currentYear;
        });

        return this.bookingsInCurrentWeek.length > 0;
      }
      return false;
    },
    onResize({ width, height }) {
      this.$nextTick(() => {
        console.log(this.$refs.fullCalendar.getApi());
        this.calendarOptions.headerToolbar = headerToolbarMobile;
        this.$refs.fullCalendar.getApi().changeView('listDay');
        console.log(`New width: ${width}, new height: ${height}`);
      });
    },
    handleEventClick(clickInfo) {
      const authStore = useAuthStore();
      const eventStore = useEventStore();
      eventStore.shiftEventAlert = "";
      eventStore.shiftEventStatus = false;
      eventStore.shiftEventMessage = "";
      eventStore.forceRemove = false;
      eventStore.canBook = true;
      console.log(clickInfo);
      const currentDate = this.$refs.fullCalendar.getApi().getDate();
      const currentDateFormatted = format(currentDate, 'yyyy-MM-dd');
      if (authStore.altUserTypeDates.includes(currentDateFormatted)) {
        console.log('date inside inactive range');
        authStore.user.usertype = authStore.user.alt_usertype;
        this.currentDateIsInAltUsertypeRange = true;
      } else {
        this.currentDateIsInAltUsertypeRange = false;
      }
      const event = clickInfo.event || clickInfo;

      // Check if extendedProps exists on the event, and use empty object as fallback
      const extendedProps = event.extendedProps || {};

      // Get the users and status from extendedProps
      const eventMembers = extendedProps.users || [];
      const eventStatus = extendedProps.status;
      const eventPast = extendedProps.past;
      const eventWeek = extendedProps.week;
      var userId = authStore.user.id;


      if (eventMembers.includes(userId)) {
        eventStore.removeEvent(clickInfo);
      } else if ([3, 4, 5, 6, 99].includes(eventStatus)) {
        if (authStore.userLevel > 1) {
          eventStore.recurringCount = 1;
          eventStore.selectEvent(clickInfo);
        } else {
          console.log('Clicked fully booked timeslot --> No action');
        }
      } else {
        eventStore.recurringCount = 1;
        console.log(authStore.user.usertype);
        console.log(authStore.user.usertype == 'duo');
        if (authStore.user.usertype === 'duo' && eventStatus === 2) {
          console.log('Clicked fully booked timeslot --> No action');
        } else {
          eventStore.selectEvent(clickInfo);
        }
      }

      // SHIFTING EVENT

      var week = event.extendedProps.week;
      let weekNumber = week;
      let matchingWeek = authStore.combinedWeeksAndTrainings?.find(
        (weekObject) => weekObject.week == weekNumber
      );

      if (
        matchingWeek &&
        Array.isArray(matchingWeek.trainings) &&
        matchingWeek.trainings.length > 0
      ) {
        const trainingStart = matchingWeek.trainings[0].start;
        // if (trainingStart + "+02:00" == event.startStr) {
        //   return false;
        // }
        //eventStore.shiftEventAlert = " heeft deze week al een training";
        if (isAtLeast1HourAway(trainingStart)) {
          eventStore.shiftEventStatus = true;
          eventStore.shiftEventMessage = trainingStart;
        } else {
          eventStore.canBook = false;
        }


      }

    },
    handleEventDidMount(eventInfo) {
      if (!eventInfo || typeof eventInfo.event !== 'object') {
        console.error('Invalid eventInfo:', eventInfo);
        return;
      }

      const calendarApi = this.$refs.fullCalendar.getApi();
      if (calendarApi.getEvents().length === 0) return;

      const authStore = useAuthStore();
      const memberStore = useMemberStore();
      const locationStore = useLocationStore();

      const userLevel = authStore.userLevel;

      const { status: eventStatus, users: eventMembers, past: eventPast, week: eventWeek } = eventInfo.event?.extendedProps || {};
      const userId = authStore.user && Object.keys(authStore.user).length > 0 ?
        authStore.user.id :
        authStore.user.id;

      const targetElement = eventInfo.el;
      let titleElement, statusElement, icon = '', bookText = "Boeken";

      if (targetElement.classList.contains('fc-list-event')) {
        titleElement = targetElement.querySelector('.fc-list-event-title');
        statusElement = document.createElement('td');
      } else if (targetElement.classList.contains('fc-timegrid-event')) {
        titleElement = targetElement.querySelector('.fc-event-title-container');
        titleElement = titleElement.parentElement; // This is the direct parent of titleElement
        statusElement = document.createElement('div');
      }

      const currentDate = this.$refs.fullCalendar.getApi().getDate();
      const currentDateFormatted = format(currentDate, 'yyyy-MM-dd');
      if (authStore.altUserTypeDates.includes(currentDateFormatted)) {
        authStore.user.usertype = authStore.user.alt_usertype;
      }
      // if(!memberIds?.includes(userId) && eventPast){
      //   targetElement.remove();
      //   return;
      // }
      const handleBookingStatus = (status, memberIds) => {
        let memberNames = '';

        // console.log('ClassList:', targetElement.classList);
        if (!memberIds.includes(userId) && targetElement.classList.contains('status--past')) {
          targetElement.remove();
          return;
        }
        if (this.userUsedCreditForWeek(eventWeek)) {
          targetElement.remove();
          return;
        }
        switch (status) {
          case 2:
            if (authStore.user.usertype !== 'duo') {
              icon = '<i class="far fa-user-group"></i><span class="mc">1/2</span>';
            } else {
              if (!memberIds.includes(userId) && authStore.hideUnavailableTimeslots) {
                targetElement.classList.add('d-none');
              }
            }

            bookText = memberIds.includes(userId) ? "Geboekt" : "Vol";
            targetElement.classList.add(memberIds.includes(userId) ? 'self-booked' : 'other-booked');
            break;
          case 3:
          case 4:
          case 5:
          case 6:
          case 9:
          case 10:
          case 99:
            icon = '<i class="fas fa-user-group"></i><span class="mc">2/2</span>';
            bookText = memberIds.includes(userId) ? "Geboekt" : "Vol";
            targetElement.classList.add(memberIds.includes(userId) ? 'self-booked' : 'other-booked');
            if (!memberIds.includes(userId) && authStore.hideUnavailableTimeslots) {
              targetElement.classList.add('d-none');
            }
            break;
          case 7:
          case 8:
            targetElement.remove();
            break;
        }

        if (userLevel > 1) {
          if (status != '99') {
            const membersData = locationStore.fetchLocationMembersByIDs(memberIds);
            if (membersData && membersData.length) {
              memberNames = generateMemberNamesDiv(membersData);
            }
          } else {
            memberNames = generateMemberNamesDiv(eventMembers);
          }

          statusElement.classList.add('status');
          statusElement.innerHTML = `${icon}<span class='badge bg-black'>${statusToString(status)}</span>${memberNames}`;
          // console.log(targetElement);
          targetElement.insertBefore(statusElement, titleElement);
          titleElement?.remove();
        } else {
          statusElement.classList.add('status');
          statusElement.innerHTML = `${icon}`;
          targetElement.insertBefore(statusElement, titleElement);
          titleElement?.remove();
        }


      };


      const generateMemberNamesDiv = (members) => {
        const membersDiv = document.createElement('div');
        membersDiv.className = 'memberData';

        members.forEach(member => {
          const memberDiv = document.createElement('div');
          memberDiv.classList.add('badge', 'bg-black');
          // If member is a string, use it directly. Otherwise, use firstname and lastname properties
          const memberName = (typeof member === 'string') ? member : `${member.firstname} ${member.lastname}`;

          memberDiv.innerHTML = `<i class="fas fa-user"></i> ${memberName}`;
          membersDiv.appendChild(memberDiv);
        });

        return membersDiv.outerHTML;
      };

      handleBookingStatus(eventStatus, eventMembers);
    },
    hideUnavailableSlots() {
      const authStore = useAuthStore();
      authStore.hideUnavailableTimeslots = !authStore.hideUnavailableTimeslots;

      const otherBookedSlots = document.querySelectorAll('.other-booked');
      for (let slot of otherBookedSlots) {
        authStore.hideUnavailableTimeslots ? slot.classList.add('d-none') : slot.classList.remove('d-none');
        // slot.style.display = authStore.hideUnavailableTimeslots ? 'none' : '';
      }
    },
    hasCreditsForWeek(week, year) {
      const authStore = useAuthStore();
      if (!authStore.user?.credits) return false;
      return Object.values(authStore.user.credits).some(credit => 
        parseInt(credit.week) === parseInt(week) && parseInt(credit.year) === parseInt(year)
      );
    },
    formattedStartDate(value) {
      console.log('formattedStartDate Value:', value);

      // Manually extract date and time components from the string
      const [datePart, timePart] = value.split('T');
      const [year, month, day] = datePart.split('-').map(num => parseInt(num, 10));
      const [hours, minutes] = timePart.split(':').map(num => parseInt(num, 10));

      // Construct a formatted string
      // const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      // const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

      const dayNames = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'];
      const monthNames = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];

      const dateObj = new Date(Date.UTC(year, month - 1, day, hours, minutes));
      const dayName = dayNames[dateObj.getUTCDay()];
      const formattedDate = `${day} ${monthNames[month - 1]} ${year} om ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

      console.log(formattedDate);
      return dayName + ' ' + formattedDate;
    },
    formattedStartDateTime(value) {
      console.log('formattedStartDateTime Value:', value);

      // Extract the time part from the input string
      const timePart = value.split('T')[1];
      const [hours, minutes] = timePart.split(':');

      // Return formatted hour and minute
      return `${hours}:${minutes}`;
    },
    fillSevenDayNav(startOfWeekDate) {
      this.SevenDayNav = [];
      let monday = startOfISOWeek(startOfWeekDate, { weekStartsOn: 1 }); // 1 for Monday
      for (let i = 0; i < 7; i++) {
        this.SevenDayNav.push(format(add(monday, { days: i }), 'yyyy-MM-dd'));
      }
    },
    async bookRecurring() {
      if(!confirm('Let op! Je staat op het punt een herhaalde training te boeken. Weet je zeker dat je dit wilt doen?')){
        return;
      }
      const authStore = useAuthStore();
      const eventStore = useEventStore();


      eventStore.selectedTimeslot = eventStore.eventInfo.event;
      const start = true;
      
      const job = authStore.user.Recurring !== false ? 'shift' : 'add';
      const id = authStore.user.id;

      await eventStore.updateRecurring(job,id,start);
      this.refetchCal();
    },

    async handleTransfer() {
      const eventStore = useEventStore();
      const modalStore = useModalStore();
      const authStore = useAuthStore();

      // Check if user is duo type
      if (authStore.user?.type === 'duo') {
        if (!confirm('Wilt u uw duo partner ook meeverplaatsen?')) {
          eventStore.keepDuo = false;
        }
      }

      try {
        const result = await eventStore.transferEvent();
        console.log('transferEvent finished with result:', result);
      } catch (error) {
        console.log('transferEvent failed with error:', error);
      }
      
      modalStore.hideModal('selectEvent');
    },

    bookShiftEvent() {
      const authStore = useAuthStore();
      const eventStore = useEventStore();
      eventStore.shift = true;
      this.bookEvent();

    },
    bookEvent() {
      const eventStore = useEventStore()
      const authStore = useAuthStore();
      const memberStore = useMemberStore();
      const modalStore = useModalStore();

      eventStore.updateEvent().then((result) => {
        console.log('updateEvent finished with result:', result);
        authStore.refetchUser();
        // If result is not an array, wrap it in an array
        const resultsArray = result.timeslots ? result.timeslots : [result];
        const errorLog = [];

        resultsArray.forEach(resultItem => {
          console.log('RESULT,USERS    ' + resultItem.users)
          if (resultItem.error) {
            if (resultItem.error == 'Lid heeft al een boeking voor deze week') {
              errorLog.push("Boeking voor week " + resultItem.week + " niet gelukt. \n");
            }
            // alert(resultItem.error);
          }

          const endTime = moment(resultItem.start, 'HH:mm:ss').add(20, 'minutes').format('HH:mm:ss');
          const newTimeslot = {
            backgroundColor: '#660000',
            className: 'status--' + String(resultItem.status),
            extendedProps: {
              dbID: resultItem.dbID,
              users: resultItem.users,
              status: String(resultItem.status),
              week: resultItem.week
            },
            start: resultItem.date + 'T' + resultItem.start + ':00',
            end: resultItem.date + 'T' + endTime
          }

          if (authStore.user && Object.keys(authStore.user).length > 0) {
            authStore.user.timeslots.push(newTimeslot);
          } else {
            authStore.user.timeslots.push(newTimeslot);
          }

          console.log('newTimeslot:  ' + JSON.stringify(newTimeslot));

          var events = this.$refs.fullCalendar.getApi().getEvents();
          const date = resultItem.date;
          const time = resultItem.start;
          const datetime = new Date(`${date}T${time}:00`);
          console.log(datetime);
          console.log(datetime.getTime());
          const matchingEvent = events.find(event => {
            const eventStart = new Date(event.startStr);
            return eventStart.getTime() === datetime.getTime();
          });
          if (matchingEvent) {
            // Update the matching event here
            console.log('match ---> ' + JSON.stringify(matchingEvent));

            this.$refs.fullCalendar.getApi().refetchEvents();
            // etc.



          }
        });
        if (errorLog.length > 0) {
          const modalStore = useModalStore();
          this.bookingResponses = errorLog;
          modalStore.setModalVisible('bookingResponse', true)
        }

      }).catch((error) => {
        console.log('updateEvent failed with error:', error);
        if (error.response?.status === 401) {
          // Specifieke afhandeling voor 401 Unauthorized
          console.error('Authentication error: Unauthorized (401)');
          authStore.logout();
        }
        // Handle the error here
      });

    },
    forceRemoveEvent() {
      const eventStore = useEventStore();
      eventStore.forceRemove = true;
      this.removeEvent();
    },
    removeEvent() {
      const eventStore = useEventStore()
      const authStore = useAuthStore();
      eventStore.updateEvent().then((result) => {
        console.log('removeEvent finished with result:', result);

        var events = this.$refs.fullCalendar.getApi().getEvents();

        const date = result.date;
        const time = result.start;
        const datetime = new Date(`${date}T${time}:00`);
        console.log(datetime);
        console.log(datetime.getTime());
        //
        const matchingEvent = events.find(event => {
          const eventStart = new Date(event.startStr);
          return eventStart.getTime() === datetime.getTime();
        });

        if (matchingEvent) {
          // Update the matching event here
          console.log('match ---> ' + JSON.stringify(matchingEvent));
          this.$refs.fullCalendar.getApi().refetchEvents();
          // etc.
        }
        authStore.refetchUser();

      }).catch((error) => {
        console.log('removeEvent failed with error:', error);

        // Handle the error here
      });
      authStore.triggerCalendarRefresh();
    },
    refetchCal() {

      this.$refs.fullCalendar.getApi().refetchEvents();

    },
    addToCalendar(training) {
      if (training.startStr) {
        var startDate = moment(training.startStr).format('YYYY-MM-DD');
        var startTime = moment(training.startStr).format('LT');
        var endTime = moment(training.endStr).format('LT');
      } else {
        var startDate = moment(training.start).format('YYYY-MM-DD');
        var startTime = moment(training.start).format('LT');
        var endTime = moment(training.end).format('LT');
      }
      const authStore = useAuthStore();
      const locationStore = useLocationStore();
      const location_id = Number(authStore.user.location_id);
      const location = locationStore.locations[location_id];
      console.log(startDate, '-', startTime, '-', endTime);
      const fullCalendarConfig = {
        name: this.calendarConfig.name,
        description: this.calendarConfig.description,
        timeZone: this.calendarConfig.timeZone,
        location: location ? `fit20 ${location.name}, ${location.address}, ${location.zip}, ${location.city}` : "",
        options: this.calendarConfig.options,
        startDate: startDate,
        startTime: startTime,
        endTime: endTime,
      }
      console.table(fullCalendarConfig);
      atcb_action(fullCalendarConfig)
    },
    toggleView() {
      const calendarApi = this.$refs.fullCalendar.getApi();
      if (calendarApi.view.type === 'timeGridWeek') {
        calendarApi.changeView('listDay');
        this.showBoxHeader = !this.showBoxHeader;
        this.calendarOptions.headerToolbar = headerToolbarMobile;
      } else if (calendarApi.view.type === 'listDay') {
        calendarApi.changeView('timeGridWeek');
        this.showBoxHeader = !this.showBoxHeader;
        this.calendarOptions.headerToolbar = headerToolbarDesktop;
      }
    },
    changeToWeekView() {
      const authStore = useAuthStore();
      if (authStore.user.usertype == 'support' || authStore.user.usertype == 'trainer') {
        // this.$refs.fullCalendar.getApi().changeView('timeGridWeek');
        this.showBoxHeader = !this.showBoxHeader;
        this.calendarOptions.headerToolbar = headerToolbarDesktop;
      }
    },
  },
  computed: {
    currentWeek() {
      const route = useRoute();
      return route.query.week;
    },
    currentYear() {
      const route = useRoute();
      return route.query.year;
    },
    showPreviousWeek(){
      const eventStore = useEventStore();
      const weekNumber = eventStore.eventInfo.event?.extendedProps?.week;
      const year = eventStore.eventInfo.event?.extendedProps?.year;
      if (!weekNumber) return false;
      
      // Get current week and year
      const now = new Date();
      const currentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const currentWeek = parseInt(moment(currentDate).isoWeek());
      const currentYear = now.getFullYear();

      let prevWeek;
      let prevYear = year;
      
      if (weekNumber == 1) {
        prevWeek = 52;
        prevYear = year - 1;
      } else {
        prevWeek = parseInt(weekNumber) - 1 == 0 ? 52 : parseInt(weekNumber) - 1;
      }

      // Only show previous week if it's not in the past (compared to current week)
      return !(prevYear < currentYear || (prevYear === currentYear && prevWeek < currentWeek));
    },

    selectedWeekTrainings() {
      // return [];
      const eventStore = useEventStore();
      const authStore = useAuthStore();
      const weekNumber = eventStore.eventInfo.event?.extendedProps?.week;
      const year = eventStore.eventInfo.event?.extendedProps?.year;

      // Check if combinedWeeksAndTrainings is defined and is an array
      if (!Array.isArray(authStore.combinedWeeksAndTrainings)) {
        console.error('combinedWeeksAndTrainings is not an array or is undefined');
        return [];
      }

      return authStore.combinedWeeksAndTrainings.find(
        (weekObject) => weekObject.week == weekNumber && weekObject.year == year
      )?.trainings || [];
    },

    previousWeekTrainings(){


      const eventStore = useEventStore();
      const authStore = useAuthStore();
      const weekNumber = eventStore.eventInfo.event?.extendedProps?.week;
      const year = eventStore.eventInfo.event?.extendedProps?.year;
      if (!weekNumber) return [];
      
      // Get current week and year
      const now = new Date();
      const currentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const currentWeek = parseInt(moment(currentDate).isoWeek());
      const currentYear = now.getFullYear();

      let prevWeek;
      let prevYear = year;
      
      if (weekNumber == 1) {
        prevWeek = 52;
        prevYear = year - 1;
      } else {
        prevWeek = parseInt(weekNumber) - 1 == 0 ? 52 : parseInt(weekNumber) - 1;
      }

      // Only show previous week if it's not in the past (compared to current week)
      if (prevYear < currentYear || (prevYear === currentYear && prevWeek < currentWeek)) {
        return [];
      }
      
      return authStore.combinedWeeksAndTrainings.find(
        (weekObject) => weekObject.week == prevWeek && weekObject.year == prevYear
      )?.trainings || [];
    },

    futureDates() {
      const eventStore = useEventStore();  // access the eventStore
      // Check if eventStore.eventInfo and eventStore.eventInfo.event.startStr exist before trying to access it
      if (eventStore.eventInfo && eventStore.eventInfo.event.startStr) {
        const dates = [];
        const startDate = parseISO(eventStore.eventInfo.event.startStr);

        for (let i = 1; i < eventStore.recurringCount; i++) {
          dates.push(addWeeks(startDate, i));
        }

        return dates.map(date => format(date, "yyyy-MM-dd'T'HH:mm:ss"));
      }
      // If eventStore.eventInfo or eventStore.eventInfo.event.startStr does not exist, return an empty array
      else {
        return [];
      }
    },
    maxWeekRange() {
      const eventStore = useEventStore();  // access the eventStore
      const currentWeek = getCurrentWeek(new Date());
      const maxWeek = currentWeek + 7;
      // Parse the event's start date and get its week number
      const eventStartDate = parseISO(eventStore.eventInfo.event.startStr);
      const eventStartWeek = getCurrentWeek(eventStartDate);

      // Calculate the maximum number of weeks
      // + 1 because 1 = 0
      const maxWeeks = maxWeek - eventStartWeek + 1;
      return maxWeeks || 0;
    },
    creditIsCurrentWeek() {
      console.log('FUNCTION: creditIsCurrentWeek');
      const authStore = useAuthStore();
      const eventStore = useEventStore();
      const memberStore = useMemberStore();

      if (authStore.user && Object.keys(authStore.user).length > 0) {
        var creditWeek = authStore.user.credits.available[0];
      } else {
        var creditWeek = authStore.user.credits.available[0];
      }

      const route = useRoute();
      const weekNumber = parseInt(route.query.week, 10);
      if (creditWeek === weekNumber.toString()) {
        return eventStore.useCredit = true;
      } else {
        return eventStore.useCredit = false;
      }
    }
  },
  mounted() {
    console.log('mounted UserCalendar');
    const route = useRoute();
    const router = useRouter();
    const weekNumber = parseInt(route.query.week, 10);
    const yearNumber = parseInt(route.query.year, 10);
    const currentDate = new Date();
    const currentDateFormatted = format(currentDate, "yyyy-MM-dd");
    const currentYear = getCurrentYear(currentDate);  // Get the current year
    const nextYear = getNextYear(currentDate);  // Get the next year
    const currentWeek = getCurrentWeek(currentDate); // Get current week number

    let startOfWeekDate;

    // If the yearNumber is not the current or next year, or the weekNumber is not between 1 and 52...
    if ((yearNumber !== currentYear && yearNumber !== nextYear) || !weekNumber || weekNumber < 1 || weekNumber > 52) {
      // ...then update the route parameters:
      // - if yearNumber is not the current or next year, set the 'year' parameter to currentYear
      // - if weekNumber is not between 1 and 52, set the 'week' parameter to currentWeek
      // - otherwise, leave the 'year' and 'week' parameters as they are
      router.push({
        path: '/calendar',
        query: {
          ...route.query,
          year: (yearNumber !== currentYear && yearNumber !== nextYear) ? currentYear : yearNumber,
          week: (!weekNumber || weekNumber < 1 || weekNumber > 52) ? currentWeek : weekNumber
        }
      });
    } else {
      // If the yearNumber is the current or next year, and the weekNumber is between 1 and 52...
      // ...then set startOfWeekDate to currentDate if weekNumber equals currentWeek,
      // and to the result of getFirstDayOfWeek(yearNumber, weekNumber) otherwise
      startOfWeekDate = (weekNumber === currentWeek) ? currentDate : getFirstDayOfWeek(yearNumber, weekNumber);
      this.$nextTick(() => this.goToDate(startOfWeekDate));
      // this.goToDate(startOfWeekDate);
      this.fillSevenDayNav(startOfWeekDate);
      this.weekHasBooking();
    }

    // Check if credit is for current week
    const authStore = useAuthStore();
    const eventStore = useEventStore();
    const memberStore = useMemberStore();

    if (authStore.user && Object.keys(authStore.user).length > 0) {
      if (authStore.user.credits.available && Object.keys(authStore.user.credits.available).length > 0) {
        const creditWeek = authStore.user.credits.available[0];
        if (creditWeek === weekNumber.toString()) {
          eventStore.useCredit = true;
        } else {
          eventStore.useCredit = false;
        }
      }
    } else {
      if (authStore.user.credits.available && Object.keys(authStore.user.credits.available).length > 0) {
        const creditWeek = authStore.user.credits.available[0];
        if (creditWeek === weekNumber.toString()) {
          eventStore.useCredit = true;
        } else {
          eventStore.useCredit = false;
        }
      }
    }

    this.$nextTick(() => {
      // this.changeToWeekView();
    });

    // this.calendarOptions.eventSources = {
    //   url: import.meta.env.VITE_API_URL + 'load-timeslots.php',
    //   method: 'GET',
    //   extraParams: {
    //     location: 1
    //   },
    //   cache: true,
    // }

  },
  beforeRouteUpdate(to, from, next) {
    // Make sure DOM is updated after route change
    this.$nextTick(function () {
      const weekNumber = parseInt(to.query.week, 10);
      const yearNumber = parseInt(to.query.year, 10);
      const currentDate = new Date();
      const currentWeek = getCurrentWeek(currentDate); // Get current week number

      let startOfWeekDate;
      startOfWeekDate = (weekNumber === currentWeek) ? currentDate : getFirstDayOfWeek(yearNumber, weekNumber);
      this.$nextTick(() => this.goToDate(startOfWeekDate));
      // this.goToDate(startOfWeekDate);
      this.fillSevenDayNav(startOfWeekDate);
      this.weekHasBooking();

      // Check if credit is for current week
      const authStore = useAuthStore();
      const eventStore = useEventStore();
      if (authStore.user && Object.keys(authStore.user).length > 0) {
        if (authStore.user.credits.available && Object.keys(authStore.user.credits.available).length > 0) {
          const creditWeek = authStore.user.credits.available?.[0];
          if (creditWeek === weekNumber.toString()) {
            eventStore.useCredit = true;
          } else {
            eventStore.useCredit = false;
          }
        }
      } else {
        if (authStore.user.credits.available && Object.keys(authStore.user.credits.available).length > 0) {
          const creditWeek = authStore.user.credits.available[0];
          if (creditWeek === weekNumber.toString()) {
            eventStore.useCredit = true;
          } else {
            eventStore.useCredit = false;
          }
        }
      }
      // Werkt niet
      // console.log('dom update');
      // console.log(this.currentDateIsInAltUsertypeRange);
      // const apiDate = this.$refs.fullCalendar.getApi().getDate();
      // const currentDateFormatted = format(apiDate, 'yyyy-MM-dd');
      // if(authStore.altUserTypeDates.includes(currentDateFormatted)) {
      //   this.currentDateIsInAltUsertypeRange = true;
      // } else {
      //   this.currentDateIsInAltUsertypeRange = false;
      // }
    });
    next();
  },
  watch: {
    'authStore.user': {
      handler(newValue) {
        if (newValue && Object.keys(newValue).length) {
          this.isUserLoaded = true;
        }
      },
      deep: true,
      immediate: true
    }
  }


}
</script>
<template>
  <main>
    <div class="box">
      <!-- <div class="box-admin my-4">
        <button class="btn btn-green" @click="changeToWeekView">Week kalendar weergaven</button>
      </div> -->
      <div class="box__header">
        <h6 class="d-flex align-items-center mb-2">
          Kalender -
          <span style="font-size: 13px;">Week {{ $route.query.week }}, {{ $route.query.year }}</span>
          <!-- <span v-if="hasCreditsForWeek($route.query.week, $route.query.year)" class="badge bg-primary ms-1">Credit
            beschikbaar</span> -->
          <template v-if="authStore.userLevel == 1">
            <button @click="menuDropdown = !menuDropdown" class="btn-actions btn btn-white ms-auto"><font-awesome-icon
                icon="fas fa-bars-filter"></font-awesome-icon></button>
            <div v-if="menuDropdown" class="menu-dropdown">
              <span class="closeDropdown" @click="menuDropdown = !menuDropdown"><font-awesome-icon
                  icon="far fa-times"></font-awesome-icon></span>
              <span class="h2">Filters</span>
              <button @click="hideUnavailableSlots">
                <font-awesome-icon v-if="authStore.hideUnavailableTimeslots"
                  icon="fas fa-circle-check"></font-awesome-icon>
                <font-awesome-icon v-if="!authStore.hideUnavailableTimeslots" icon="far fa-circle"></font-awesome-icon>
                Alleen beschikbare tijdsloten tonen
              </button>
            </div>
          </template>
        </h6>
        <div class="sevendays" v-if="showBoxHeader">
          <span v-for="(day, index) in SevenDayNav" :key="index" @click="goToDate(day);"
            :class="{ 'active': activeDay === day }">
            <span class="dow">{{ navDate(day)[0] }}</span>
            <span class="dn">{{ navDate(day)[1] }}</span>
          </span>
        </div>
        <div class="calendar-nav">
          <span class="prev" @click="goToDayOfWeek('prev')">Vorige week</span>
          <span class="date" v-if="showBoxHeader">{{ formatCurrentDate() }}</span>
          <span class="next" @click="goToDayOfWeek('next')">Volgende week</span>
        </div>
      </div>

      <!-- Is broken view -->
      <!-- <div v-if="authStore.user && authStore.userLevel > 1" class="">
        <button class="btn btn-green" @click="toggleView">Toggle Kalender</button>
      </div> -->

      <div v-if="weekHasBooking()" class="currentBooking training-overview">
        <span class="d-block text-center fw-bold text-white mb-2">Al geboekt voor deze week</span>
        <div v-for="(booking, bIndex) in bookingsInCurrentWeek" :key="booking.extendedProps.dbID" @click="handleEventClick(booking)"
            class="training" :class="bIndex < bookingsInCurrentWeek.length - 1 ? 'mb-2' : ''">
            <span class="date">
                {{ capitalizeFirstLetter(moment(booking?.start).format('ddd D MMMM YYYY')) }}, {{
                moment(booking?.start).format('LT') }} - {{ moment(booking?.end).format('LT') }}

                <span v-if="authStore.user?.credits &&authStore.user?.credits[booking.extendedProps.dbID]" class="mx-4 credit-message">
                Inhaaltraining week {{ authStore.user?.credits[booking.extendedProps.dbID].week }}
              </span>

            </span>
            
            <span class="btn btn-green"><font-awesome-icon icon="fas fa-eye" /></span>
            
           
        </div>
    </div>

      <div v-if="!hasCreditsForWeek($route.query.week, $route.query.year)" class="box__body app-calendar" :class="showBoxHeader ? '' : 'app-calendar-admin'" style="padding-top: 10px;" v-resize="onResize">
        <FullCalendar class="app-calendar" ref="fullCalendar" :options='calendarOptions'>
        </FullCalendar>
      </div>
      <div v-else class="box__body">
        <div class="alert alert-info">
          Training week {{ currentWeek }} wordt ingehaald
        </div>
      </div>
    </div>
  </main>
  <Modal :id="'selectEvent'" title="" v-if="modalStore.isModalVisible('selectEvent')">
    <div>
      <div class="row g-3">
        <div class="col">
          <span class="time h5 text-black">{{ formattedStartDate(eventStore.eventInfo.event.startStr) }}</span>
          <span class="h5 d-block" style="font-size: 12px;" v-for="(date, index) in futureDates" :key="index">
            + {{ formattedStartDate(date) }}
          </span>
        </div>
      </div>
      <div class="row mt-2" v-if="currentDateIsInAltUsertypeRange">
        <div class="col">
          <span class="d-block alert alert-info">
            Deze boeking wordt gedaan als 'flex' lid. <br>Vanwege een inactieve duo partner.
          </span>
        </div>
      </div>
      <div class="row mt-2" v-if="authStore.userLevel > 1 && eventStore.eventInfo.members">
        <div class="col-12 col-lg-6" v-for="(member, index) in eventStore.eventInfo.members" :key="index">
          <strong>{{ memberStore.fullName(member) }}</strong><br>
          <span>{{ member.email }}</span>
        </div>
      </div>
      <!-- <div v-if="(authStore.userLevel == 2) && authStore.user && Object.keys(authStore.user).length <= 0" class="row g-3 mt-2">
          <div class="col-12">
            <input class="form-control" type="search" v-model="memberStore.search" placeholder="Zoek lid..">
          </div>
          <div class="col-12">
            <select class="form-select" id="selectedMember" v-model="authStore.user">
              <option value="" disabled>Kies een member</option>
              <option v-for="(member, index) in locationStore.filteredMembers" :key="index" :value="member">
                {{ memberStore.fullName(member) }}
              </option>
            </select>
          </div>
        </div> -->
      <div class="row mt-2"
        v-if="eventStore.eventInfo.event.extendedProps.status == 1 || eventStore.eventInfo.event.extendedProps.status == 2">
        <div class="col">
          <div class="form-group mt-3"
            v-if="hasCreditsForWeek(getWeekFromDate(eventStore.eventInfo.event.startStr), moment(eventStore.eventInfo.event.startStr).year()) && eventStore.recurringCount <= 1 && !creditIsCurrentWeek">
            <span class="h5 d-block">Credit boeking gebruiken?</span>
            <label for="creditUsed" class="switch">
              <input type="checkbox" id="creditUsed" v-model="eventStore.useCredit" :disabled="creditIsCurrentWeek">
              <span class="slider round"></span>
            </label>
          </div>
          <div class="form-group mt-3"
            v-if="hasCreditsForWeek(getWeekFromDate(eventStore.eventInfo.event.startStr), moment(eventStore.eventInfo.event.startStr).year()) && eventStore.recurringCount <= 1 && creditIsCurrentWeek">
            <span class="d-block text-center alert alert-info w-100 mb-2">Credit boeking wordt gebruikt.</span>
          </div>
          <hr v-if="!eventStore.useCredit && 1 == 2">
          <!-- TEMP DISABLE RECURRING -->
          <div class="form-group mt-3" v-if="!eventStore.recurringFixed && !eventStore.useCredit && 1 == 2">
            <span class="h5 d-block">Weken vooruit boeken: {{ eventStore.recurringCount - 1 }}</span>
            <!-- <label for="recurring" class="form-label fw-bold mb-1">Weken vooruit boeken: {{ eventStore.recurringCount - 1 }}</label> -->
            <input v-model="eventStore.recurringCount" type="range" class="form-range" id="recurring" min="1"
              :max="maxWeekRange" :disabled="maxWeekRange <= 1 && 1 == 2">
          </div>

          <span class="d-block alert alert-warning" v-if="maxWeekRange <= 1 && 1 == 2">Kan niet verder vooruit boeken
            dan
            huidige boekingsweek.</span>
          <hr v-if="!eventStore.recurringFixed && !eventStore.useCredit && 1 == 2">
          <div v-if="!eventStore.useCredit && 1 == 2">
            <span class="h5 d-block">Automatische afspraak maken van <strong>{{
            formattedRecurringDate(eventStore.eventInfo.event) }}</strong>?</span>
            <label for="recurringFixed" class="switch">
              <input type="checkbox" id="recurringFixed" v-model="eventStore.recurringFixed">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <!-- <span>{{ week }}</span> -->
      </div>
      <hr>

      <!-- <button v-if="!eventStore.shiftEventStatus && authStore.user && (authStore.isAdmin || authStore.user?.usertype == 'support')" :disabled="authStore.user == null || authStore.user == '' || eventStore.eventFullyBooked" class="btn btn-green w-100 mt-2" @click="bookEvent">Boeken</button>
        <div v-if="eventStore.shiftEventStatus && authStore.user && (authStore.isAdmin || authStore.user?.usertype == 'support')">
          <p class="mt-4">Training van {{ formattedStartDate(eventStore.shiftEventMessage) }} verplaatsen?</p>
          <button :disabled="authStore.user == null || authStore.user == '' || eventStore.eventFullyBooked" class="btn btn-green w-50 mt-2" @click="bookShiftEvent">Verplaatsen</button>

          <button :disabled="authStore.user == null || authStore.user == '' || eventStore.eventFullyBooked" class="btn btn-primary mx-2 mt-2" @click="bookEvent">Extra Boeken</button>
        </div> -->


      <div class="row mt-3">
        <span v-if="!eventStore.shiftEventStatus && !eventStore.canBook" class="alert alert-danger d-block mt-3">1 uur van te voren afzeggen is verlopen. Verplaatsen is niet meer mogelijk.</span>
        <button v-if="authStore.userLevel > 1 && eventStore.eventFullyBooked" type="button" class="btn btn-red me-2"
          @click="removeEvent">Afzeggen</button>
        <div class="recurring alert alert-info mt-3" v-if="!eventStore.shiftEventStatus && eventStore.canBook">
          <h6 class="fw-semibold d-flex align-items-center">Deze week is nog geen trainig geboekt</h6>
        </div>
        <button v-if="!eventStore.shiftEventStatus && eventStore.canBook && !hasCreditsForWeek(eventStore.eventInfo?.event?.extendedProps?.week, parseInt(moment(eventStore.eventInfo?.event?.start).format('YYYY')))"
          :disabled="Object.keys(eventStore.eventInfo).length <= 0" class="btn btn-green"
          @click="bookEvent">Training eenmalig boeken</button>

          <div class="recurring alert alert-info mt-3" v-if="eventStore.shiftEventStatus">
            <h6 class="fw-semibold d-flex align-items-center">Training van deze week</h6>
            {{ formattedStartDate(eventStore.shiftEventMessage) }}
          </div>
          <button v-if="authStore.user" 
            class="btn btn-primary me-2 mt-2 mb-3" 
            @click="openMoveModal()">
            Training Verplaatsen/Inhalen
          </button>
        <div class="recurring alert alert-info mt-3" v-if="authStore.user?.recurring">
          <h6 class="fw-semibold d-flex align-items-center">Mijn vaste wekelijkse training</h6>
          {{ capitalizeFirstLetter(authStore.user?.recurring.dayname) }} om {{ authStore.user?.recurring.start }} - {{
          authStore.user?.recurring.end }}
          <!-- <button @click="eventStore.stopRecurring" class="btn btn-red fw-bold float-end"
            style="font-size: 0.8rem;">Stop herhalen</button> -->
        </div>
        <button v-if="(eventStore.eventInfo.event.extendedProps.recurringStatus == 1 || eventStore.eventInfo.event.extendedProps.recurringStatus == 2) && eventStore.canBook"
          :disabled="Object.keys(eventStore.eventInfo).length <= 0" class="mt-2 btn btn-green"
          @click="bookRecurring">Vaste wekelijks training verzetten</button>
        


        
        
          <!-- <div v-if="eventStore.shiftEventStatus">
          <p class="mt-4">Training van {{ formattedStartDate(eventStore.shiftEventMessage) }} verplaatsen?</p>


          <button :disabled="Object.keys(eventStore.eventInfo).length <= 0" class="btn btn-green"
            @click="bookShiftEvent">Eenmalig Verplaatsen</button>
        </div> -->
      </div>
    </div>
  </Modal>

  <Modal :id="'moveTraining'" title="Training Verplaatsen/Inhalen" v-if="modalStore.isModalVisible('moveTraining')">
    <div class="container">
      <h5>Kies training om te verplaatsen:</h5>
      
      <!-- Previous week trainings -->
      <div class="mb-3" v-if="showPreviousWeek">
        <h6>Vorige week (Week {{ eventStore.eventInfo.event?.extendedProps?.week == 1 ? 52 : parseInt(eventStore.eventInfo.event?.extendedProps?.week) - 1 }})</h6>
        <div v-if="previousWeekTrainings.length > 0 && !Object.values(memberStore.selectedMember?.credits || {}).some(credit => 
              credit.year === (eventStore.eventInfo.event?.extendedProps?.week == 1 ? parseInt(eventStore.eventInfo.event?.extendedProps?.year) - 1 : eventStore.eventInfo.event?.extendedProps?.year) && 
              credit.week === (eventStore.eventInfo.event?.extendedProps?.week == 1 ? 52 : parseInt(eventStore.eventInfo.event?.extendedProps?.week) - 1)
            )">
          <div v-for="training in previousWeekTrainings" :key="training.extendedProps?.dbID">
            <button class="btn btn-outline-primary me-2 mb-2" @click="handleTransfer({
              from: training.extendedProps?.dbID,
              to: eventStore.eventInfo.event?.extendedProps?.dbID,
              location: authStore.user.location_id
            }); closeMoveModal()">
              {{ formattedStartDate(training.start) }}
            </button>
          </div>
        </div>
        <div v-else>

          <button class="btn btn-outline-primary me-2 mb-2" 
            v-if="!Object.values(memberStore.selectedMember?.credits || {}).some(credit => 
              credit.year === (eventStore.eventInfo.event?.extendedProps?.week == 1 ? parseInt(eventStore.eventInfo.event?.extendedProps?.year) - 1 : eventStore.eventInfo.event?.extendedProps?.year) && 
              credit.week === (eventStore.eventInfo.event?.extendedProps?.week == 1 ? 52 : parseInt(eventStore.eventInfo.event?.extendedProps?.week) - 1)
            )"
            @click="handleTransfer({
              from: eventStore.eventInfo.event?.extendedProps?.week == 1 ? 52 : parseInt(eventStore.eventInfo.event?.extendedProps?.week) - 1,
              credit:  (eventStore.eventInfo.event?.extendedProps?.week == 1 ? parseInt(eventStore.eventInfo.event?.extendedProps?.year) - 1 : eventStore.eventInfo.event?.extendedProps?.year)+'_'+(eventStore.eventInfo.event?.extendedProps?.week == 1 ? 52 : parseInt(eventStore.eventInfo.event?.extendedProps?.week) - 1),
              to: eventStore.eventInfo.event?.extendedProps?.dbID,
              location: authStore.user.location_id
            }); closeMoveModal()">
            Training inhalen van week {{ eventStore.eventInfo.event?.extendedProps?.week == 1 ? 52 : parseInt(eventStore.eventInfo.event?.extendedProps?.week) - 1 }}
          </button>
        </div>
      </div>
      
      <!-- Current week trainings -->
      <div class="mb-3">
        <h6>Huidige week (Week {{ eventStore.eventInfo.event?.extendedProps?.week }})</h6>
        <div v-if="selectedWeekTrainings.length > 0">
          <div v-for="training in selectedWeekTrainings" :key="training.extendedProps?.dbID">
            <button class="btn btn-outline-primary me-2 mb-2" @click="handleTransfer({
              from: training.extendedProps?.dbID,
              to: eventStore.eventInfo.event?.extendedProps?.dbID,
              location: authStore.user.location_id
            })">
              {{ formattedStartDate(training.start) }}
            </button>
          </div>
        </div>
        <div v-else>
          <button class="btn btn-outline-primary me-2 mb-2" 
            v-if="!Object.values(authStore.user?.credits || {}).some(credit => 
              credit.year === eventStore.eventInfo.event?.extendedProps?.year && 
              credit.week === eventStore.eventInfo.event?.extendedProps?.week
            )"
            @click="handleTransfer({
              from: eventStore.eventInfo.event?.extendedProps?.week,
              credit: eventStore.eventInfo.event?.extendedProps?.year+'_'+eventStore.eventInfo.event?.extendedProps?.week,
              to: eventStore.eventInfo.event?.extendedProps?.dbID,
              location: authStore.user.location_id
            }); closeMoveModal()">
            Training inhalen van week {{ eventStore.eventInfo.event?.extendedProps?.week }}
          </button>
        </div>
      </div>
      
      <!-- Next week trainings -->
      <!-- <div class="mb-3">
        <h6>Volgende week (Week {{ eventStore.eventInfo.event?.extendedProps?.week == 52 ? 1 : parseInt(eventStore.eventInfo.event?.extendedProps?.week) + 1 }})</h6>
        <div v-if="nextWeekTrainings.length > 0 && !Object.values(memberStore.selectedMember?.credits || {}).some(credit => 
              credit.year === (eventStore.eventInfo.event?.extendedProps?.week == 52 ? parseInt(eventStore.eventInfo.event?.extendedProps?.year) + 1 : eventStore.eventInfo.event?.extendedProps?.year) && 
              credit.week === (eventStore.eventInfo.event?.extendedProps?.week == 52 ? 1 : parseInt(eventStore.eventInfo.event?.extendedProps?.week) + 1)
            )">
          <div v-for="training in nextWeekTrainings" :key="training.extendedProps?.dbID">
            <button class="btn btn-outline-primary me-2 mb-2" @click="handleTransfer({
              from: training.extendedProps?.dbID,
              to: eventStore.eventInfo.event?.extendedProps?.dbID,
              location: locationStore.selectedLocation.id
            }); closeMoveModal()">
              {{ formattedStartDate(training.start) }}
            </button>
          </div>
        </div>
        <div v-else>
          <button class="btn btn-outline-primary me-2 mb-2" 
            v-if="!Object.values(memberStore.selectedMember?.credits || {}).some(credit => 
              credit.year === (eventStore.eventInfo.event?.extendedProps?.week == 52 ? parseInt(eventStore.eventInfo.event?.extendedProps?.year) + 1 : eventStore.eventInfo.event?.extendedProps?.year) && 
              credit.week === (eventStore.eventInfo.event?.extendedProps?.week == 52 ? 1 : parseInt(eventStore.eventInfo.event?.extendedProps?.week) + 1)
            )"
            @click="handleTransfer({
              from: eventStore.eventInfo.event?.extendedProps?.week == 52 ? 1 : parseInt(eventStore.eventInfo.event?.extendedProps?.week) + 1,
              credit: (eventStore.eventInfo.event?.extendedProps?.week == 52 ? parseInt(eventStore.eventInfo.event?.extendedProps?.year) + 1 : eventStore.eventInfo.event?.extendedProps?.year)+'_'+(eventStore.eventInfo.event?.extendedProps?.week == 52 ? 1 : parseInt(eventStore.eventInfo.event?.extendedProps?.week) + 1),
              to: eventStore.eventInfo.event?.extendedProps?.dbID,
              location: locationStore.selectedLocation.id
            }); closeMoveModal()">
            Training inhalen van week {{ eventStore.eventInfo.event?.extendedProps?.week == 52 ? 1 : parseInt(eventStore.eventInfo.event?.extendedProps?.week) + 1 }}
          </button>
        </div>
      </div> -->

      <div class="mt-3">
        <button class="btn btn-secondary" @click="closeMoveModal()">Annuleren</button>
      </div>
    </div>
  </Modal>



  <Modal :id="'removeEvent'" title="" v-if="modalStore.isModalVisible('removeEvent')">
    <div>
      <div class="row g-3">
        <div class="col" v-if="eventStore.eventInfo.event.startStr">
          <span class="time h4">
            {{ capitalizeFirstLetter(moment(eventStore.eventInfo.event.startStr).format('dddd D MMMM YYYY')) }}
          </span>
          <span class="timeslot">
            Tijdslot: {{ formattedStartDateTime(eventStore.eventInfo.event.startStr) }} - {{
            formattedStartDateTime(eventStore.eventInfo.event.endStr) }}
          </span>
          <span
            v-if="!isAtLeast1HourAway(eventStore.eventInfo.event.startStr) && !isPast(parseISO(eventStore.eventInfo.event.start))"
            class="alert alert-danger d-block mt-3">1 uur van te voren afzeggen is verlopen. Bij annulering krijgt u
            geen
            inhaaltraining.</span>

        </div>
        <div class="col" v-if="!eventStore.eventInfo.event.startStr">
          <span class="time h4">
            {{ capitalizeFirstLetter(moment(eventStore.eventInfo.event.start).format('dddd, D MMMM, YYYY')) }}
          </span>
          <span class="timeslot">
            Tijdslot: {{ moment(eventStore.eventInfo.event.start).format('LT') }} - {{
            moment(eventStore.eventInfo.event.end).format('LT') }}
          </span>
          <span
            v-if="!isAtLeast1HourAway(eventStore.eventInfo.event.start) && !isPast(parseISO(eventStore.eventInfo.event.start))"
            class="alert alert-danger d-block mt-3">1 uur van te voren afzeggen is verlopen. Bij annulering krijgt u
            geen
            inhaaltraining.</span>
        </div>

      </div>
      <div class="row mt-3">
        <div class="buttons">
          <button v-if="isAtLeast1HourAway(eventStore.eventInfo.event.startStr) && eventStore.eventInfo.event.startStr"
            type="button" class="btn btn-red me-2" @click="removeEvent">Afzeggen</button>
          <button v-if="isAtLeast1HourAway(eventStore.eventInfo.event.start) && !eventStore.eventInfo.event.startStr"
            type="button" class="btn btn-red me-2" @click="removeEvent">Afzeggen</button>
          <button
            v-if="!isAtLeast1HourAway(eventStore.eventInfo.event.start) && !isPast(parseISO(eventStore.eventInfo.event.start))"
            type="button" class="btn btn-red me-2" @click="forceRemoveEvent">Afzeggen zonder inhaaltraining</button>
          <button type="button" class="btn btn-green" @click="addToCalendar(eventStore.eventInfo.event)">Zet in
            agenda</button>
        </div>
      </div>
    </div>
  </Modal>
  <Modal :id="'bookingResponse'" title="" v-if="modalStore.isModalVisible('bookingResponse')">
    <h3 v-for="response in bookingResponses"> {{ response }}</h3>
  </Modal>
</template>

<style lang="scss" scoped>
h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

span.time {
  display: block;
  color: var(--color-green-darker);
  font-weight: 600;

  &.h5 {
    font-size: 16px;
  }
}

.box__header {
  display: block;
}

.currentBooking {
  padding: 10px 20px 20px;
  background: var(--color-green-darker);
}

.btn-lg {
  font-size: 1.1rem;
  font-weight: bold;
}

.calendar-nav {
  display: flex;

  * {
    flex: 0 0 33.33%;
  }

  .prev,
  .next {
    padding: 0;
    border: 0;
    background: transparent;
    font-size: 0.9em;
    font-weight: 500;
    color: #32B768;
  }

  .date {
    font-size: 0.9em;
    font-weight: 500;
    color: black;
    text-align: center;
  }

  .next {
    text-align: right;
  }
}

.sevendays {
  display: flex;
  margin-bottom: 0.75rem;

  &>span {
    display: flex;
    flex: 1 0 14.2875%;
    line-height: 1.3;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px;
    background: var(--color-green-darker);
    color: white;

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    // Current date
    &.active {
      background: var(--color-green);
    }

    .dow {
      color: #A3FFC8;
      font-size: 0.75rem;
    }

    .dn {
      font-size: 0.75rem;
    }
  }
}
</style>