import { defineStore } from 'pinia'
import { useMemberStore } from './MemberStore'
import { useLocationStore } from './LocationStore'
import { useEventStore } from './EventStore'
export const useModalStore = defineStore('modal', {
  state: () => ({
    modals: {},
  }),
  actions: {
    setModalVisible(id, visible, storeName, functionName) {
      this.modals[id] = visible;
      console.log('id' + id);
      console.log(visible);
      console.log(storeName);
      console.log(functionName);
      if (storeName && functionName) {
        if (storeName === 'memberStore') {
          const store = useMemberStore();
          store[functionName]()
        } else if (storeName === 'locationStore') {
          const store = useLocationStore();
          store[functionName]()
        }
      }
      const memberStore = useMemberStore();
      const eventStore = useEventStore();
      const locationStore = useLocationStore();
 
        memberStore.newMember = {
          id : false,
          firstname: "",
          lastname: "",
          email: "",
          usertype: "",
          notify: false,
          phone: "",
          location: locationStore.selectedLocation?.id
         };
      
      if(visible == false){
     
        if(!memberStore.isKmtMemberValid && memberStore.selectedMember?.id) {
          this.modals['eventNote'+ memberStore.selectedMember.id] = false;
        }
       
        memberStore.kmt.showDuoForm = false;
        memberStore.kmt.memberInfo = {};
        memberStore.kmt.duoInfo = {};
        eventStore.showKMT = false;

        // eventStore.eventInfo = {};
        if(memberStore.selectedMember?.id) {
          this.modals['eventNote'+ memberStore.selectedMember.id] = false;
        }

        
        // memberStore.selectedMember = {};
      }
    },
    isModalVisible(id) {
      return !!this.modals[id]
    },
  },
})

