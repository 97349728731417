import { defineStore } from "pinia";
import { useMemberStore } from "./MemberStore";
import { useLocationStore } from "./LocationStore";
import { useModalStore } from "./ModalStore";
import { useAuthStore } from "./AuthStore";
import moment from "moment";
import { format, parseISO, addWeeks, startOfISOWeek, add } from "date-fns";
import axios from "axios";
import { getWeekFromDate, isAtLeast24HoursAway } from "../helpers/functions";
import { date } from "yup";

export const useEventStore = defineStore("event", {
  state: () => ({
    events: [],
    loader: [],
    eventInfo: {},
    selectedTimeslot: {},
    recurringCount: "1",
    recurringFixed: false,
    useCredit: false,
    error: null,
    keepDuo: true,
    shift: false,
    recurringShift: false,
    extra: false,
    shiftEventAlert: "",
    shiftEventMessage: "",
    shiftEventStatus: false,
    forceRemove: false,
    canBook: true,
  }),
  getters: {
    eventFullyBooked(state) {
      if (
        [0, 3, 4, 5, 6, 7, 8, 9, 10, 99].includes(
          state.eventInfo.event.extendedProps.status
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    recurringAvailable(state) {
      const memberStore = useMemberStore();
      if([0,3, 4, 5, 6,7,8,9,10, 99].includes(this.selectedTimeslot?.status)) {
        return true;
      } else if (
        this.selectedTimeslot?.status !== 1 &&
        memberStore.selectedMember?.usertype &&
        (memberStore.selectedMember?.usertype == "duo" ||
          memberStore.selectedMember?.usertype == "solo")
      ) {
        return true;
      } else {
        return false;
      }
    },
    canShiftEvent(state) {
      return true;
      //   var date = new Date(state.shiftEventMessage);
      //   console.log(date);
      //   console.log(!isAtLeast24HoursAway(date));
      //   if(!isAtLeast24HoursAway(date)){
      //     return false;
      //   }
      //   return true;
      // } else {
      //   return false;
      // }

      // if(!isAtLeast24HoursAway(memberStore.selectedMember.shiftEventMessage)){
      //   return false;
      // }
      // return true;
    },
  },
  actions: {
    async selectTimeslot(timeslot, showModal) {
      this.selectedTimeslot = timeslot;
      const modalStore = useModalStore();
      if (showModal === true) {
        modalStore.setModalVisible("selectTimeslot", true);
      }
      // console.log(member);
      // console.log(showModal);
      // this.selectedMemberLoad = JSON.parse(JSON.stringify(member));
      // this.selectedMember = JSON.parse(JSON.stringify(member));

      // try {
      //   const userData = await this.fetchUserData(member);

      //   if (userData) {
      //     this.selectedMember = userData;
      //     const locationStore = useLocationStore();

      //     const foundMemberIndex = locationStore.selectedLocation.members.findIndex(m => m.id === member.id);
      //     if (foundMemberIndex !== -1) {
      //       locationStore.selectedLocation.members[foundMemberIndex] = userData;
      //     }
      //   }
      // } finally {
      //   const modalStore = useModalStore();
      //   if (showModal === true) {
      //     modalStore.setModalVisible('selectTimeslot', true);
      //   }
      // }
    },
    selectEvent(eventInfo) {
      const modalStore = useModalStore();
      const locationStore = useLocationStore();
      if (eventInfo.event) {
        this.eventInfo = eventInfo;
        this.eventInfo.members = locationStore.fetchLocationMembersByIDs(
          eventInfo.event.extendedProps.users
        );
        this.eventInfo.recurringStatus = eventInfo.event.extendedProps.recurringStatus;
        this.eventInfo.recurring = eventInfo.event.extendedProps.recurring;
      } else {
        this.eventInfo = {
          event: eventInfo,
        };
        this.eventInfo.members = locationStore.fetchLocationMembersByIDs(
          eventInfo.event.extendedProps.users
        );
      }
      modalStore.setModalVisible("selectEvent", true);
      // console.log(eventInfo);
    },
    removeEvent(eventInfo) {
      const modalStore = useModalStore();
      // console.log('removeEvent ---> ' + eventInfo);
      // console.log('removeEvent Modal opening');
      if (eventInfo.event) {
        this.eventInfo = eventInfo;
      } else {
        this.eventInfo = {
          event: eventInfo,
        };
      }
      modalStore.setModalVisible("removeEvent", true);
      // console.log(eventInfo);
    },
    filteredNotes(member) {
      if (member.notes) {
        return member.notes.filter(
          (note) => note.timeslot == this.eventInfo.event.extendedProps.dbID
        );
      }
    },
    async stopRecurring() {
      const authStore = useAuthStore();
      if (confirm("Herhalende afspraak stop zetten?")) {
        const params = {
          user: authStore.user.id,
          token: authStore.user.token,
          location_id: authStore.user.location_id,
          recurring: "false",
        };
        try {
          console.log("Voeg toe: " + JSON.stringify(params));
          const response = await axios.post(
            import.meta.env.VITE_API_URL + "update-timeslot.php",
            JSON.stringify(params)
          );
          console.log("response " + JSON.stringify(response.data));
          authStore.user.recurring = response.data.recurring;
        } catch (error) {
          console.log(error);
          if (error.response?.status === 401) {
            // Specifieke afhandeling voor 401 Unauthorized
            console.error("Authentication error: Unauthorized (401)");
            authStore.logout();
          }
        }
      }
    },
    async updateRecurring(job,id,start) {
     
      
      const authStore = useAuthStore();
      const memberStore = useMemberStore();
      const locationStore = useLocationStore();
      const modalStore = useModalStore();
      let user = memberStore.selectedMember?.id;
      if(id !== false){
        user = id;
      }

      const locationId = locationStore.selectedLocation?.id ?? authStore.user.location_id;
      const fromdate = start;
      const params = {
        location_id: locationId,
        day: this.selectedTimeslot.day,
        fromdate: fromdate,
        start: this.selectedTimeslot.start,
        end: this.selectedTimeslot.end,
        user: user,
        job: job,
        token: authStore.user.token
      };
      console.log('Recurring: '+JSON.stringify(params, null, 2));

      const response = await axios.get(`${import.meta.env.VITE_API_URL}update-recurring.php`, { params });
      
      console.log( JSON.stringify(response.data, null, 2));

      
      const recurring =  response.data;
      
      if(locationStore.selectedLocation?.recurring){
        locationStore.selectedLocation.recurring = recurring;
       

        const member = memberStore.selectedMember;
        memberStore.selectedMember = null;
        memberStore.selectMember(member, false);
        modalStore.setModalVisible('selectTimeslot', false);
      }else{
        //authStore.user.recurring = recurring;

        
        modalStore.setModalVisible('selectEvent', false)
      }
      
     
     
      // return response.data;
    },
    async updateEvent() {
      const modalStore = useModalStore();
      const memberStore = useMemberStore();
      const authStore = useAuthStore();
      const locationStore = useLocationStore();
      var eventInfo = this.eventInfo;
      var eventStatus = eventInfo.event.extendedProps.status;
      var eventMembers = eventInfo.event.extendedProps.users;

      
      if(authStore.user.usertype == 'solo' && eventStatus !== 1){
        if(!confirm("Let op! Hier staat al iemand ingepland, dus je traint samen. Doorgaan met boeken?")){
          modalStore.setModalVisible('selectEvent', false);
          
          return;
        } 
      }
      //console.log('old status: ' + eventStatus);
      var member = authStore.user;
      if (
        authStore.isAdmin ||
        authStore.userLevel == 2 ||
        authStore.userLevel == 3
      ) {
        member = memberStore.selectedMember;
      }
      if (memberStore.isKmtMemberValid) {
        var membership = "kmt";
        var eventStatus = 7;
      } else {
        var membership = member.usertype;
      }
      if (
        membership == "duo" &&
        memberStore.selectedMember?.alt_usertype &&
        memberStore.selectedMember.alt_usertype == "flex" &&
        !memberStore.selectedMember.inactive
      ) {
        var usertype_from = memberStore.selectedMember.alt_usertype_from;
        var usertype_until = memberStore.selectedMember.alt_usertype_until;

        var formattedDate = format(
          new Date(eventInfo.event.start),
          `yyyy-MM-dd`
        );
        console.log("formatted date: " + formattedDate);
        if (
          (usertype_from === false || formattedDate >= usertype_from) &&
          (usertype_until === false || formattedDate <= usertype_until)
        ) {
          membership = "flex";
        }
      }
      this.keepDuo = true;
      if (
        this.shift == true &&
        (authStore.isAdmin ||
          authStore.userLevel == 2 ||
          authStore.userLevel == 3) &&
        eventInfo.event.extendedProps.status !== 2 &&
        member.usertype == "duo" &&
        !confirm("Duo partner ook verplaatsen?")
      ) {
        membership = "flex";
        this.keepDuo = false;
      } else if (
        this.shift == true &&
        (authStore.isAdmin ||
          authStore.userLevel == 2 ||
          authStore.userLevel == 3) &&
        eventInfo.event.extendedProps.status == 2 &&
        member.usertype == "duo"
      ) {
        membership = "flex";
        this.keepDuo = false;
      } else if (
        this.extra == true &&
        (authStore.isAdmin ||
          authStore.userLevel == 2 ||
          authStore.userLevel == 3) &&
        member.usertype == "duo" &&
        eventInfo.event.extendedProps.status !== 2 &&
        !confirm("Duo partner ook extra boeken?")
      ) {
        membership = "flex";
        this.keepDuo = false;
      } else if (
        this.extra == true &&
        (authStore.isAdmin ||
          authStore.userLevel == 2 ||
          authStore.userLevel == 3) &&
        member.usertype == "duo" &&
        eventInfo.event.extendedProps.status == 2
      ) {
        membership = "flex";
        this.keepDuo = false;
      }

      let method, state;
      method = "add";
      if (parseInt(eventStatus) === 7) {
        state = 8;
      } else {
        if (parseInt(eventStatus) === 1) {
          method = "add";
          switch (membership) {
            case "flex":
              state = 2;
              break;
            case "duo":
              state = 3;
              break;
            case "solo":
              state = 4;
              break;
            case "kmt":
              state = 9;
              break;
            default:
              break;
          }
        } else {
          if (eventMembers.includes(parseInt(member.id))) {
            console.log("REMOVE");
            method = "remove";
            switch (membership) {
              case "duo":
                state = 1;
                break;
              case "solo":
                if (eventStatus === 6) {
                  state = 2;
                } else {
                  state = 1;
                }
                break;
              case "flex":
                if (eventStatus === 6) {
                  state = 4;
                } else if (eventStatus === 5) {
                  state = 2;
                } else {
                  state = 1;
                }
                break;
              case "kmt":
                if (eventStatus === 6) {
                  state = 4;
                } else if (eventStatus === 5) {
                  state = 2;
                } else {
                  state = 1;
                }
                break;
              default:
                break;
            }
          } else {
            switch (membership) {
              case "duo":
                method = "full";
                state = eventStatus;
                break;
              case "solo":
                if (eventStatus === 2) {
                  method = "add";
                  state = 6;
                } else {
                  method = "full";
                  state = eventStatus;
                }
                break;
              case "flex":
                if (eventStatus === 2) {
                  method = "add";
                  state = 5;
                } else {
                  method = "full";
                  state = eventStatus;
                }
                break;

              default:
                break;
            }
          }
        }
      }

      if (memberStore.isKmtMemberValid) {
        method = "add";
      }
      if (this.shift == true) {
        method = "shift";
        this.shift = false;
      }
      if (method == "full") {
        console.log("FULL");
      }
      if (method !== "full") {
        console.log("NOT FULL");
        var params = {
          job: method,
          start: eventInfo.event.startStr,
          end: eventInfo.event.endStr,
          token: authStore.user.token,
          // state : state,
          // users : eventMembers,
          status: eventStatus,
        };
        if (memberStore.isKmtMemberValid) {
          params.kmt = {
            memberInfo: memberStore.kmt.memberInfo,
            duoInfo: memberStore.kmt.duoInfo,
          };

          params.location = locationStore.selectedLocation.id;
        } else {
          params.user = member.id;
          params.location = member.location_id;
        }
        if ((this.recurringCount > 1) & !this.recurringFixed) {
          params.recurring = this.recurringCount;
        }
        if (this.recurringFixed) {
          params.recurring = "true";
        }
        if (this.useCredit) {
          console.log("--------USE CREDIT CHECK--------");

          var weekNumber = getWeekFromDate(eventInfo.event.startStr);
          console.log(eventInfo.event.startStr);
          console.log(weekNumber);

          var availableCredit = authStore.user.credits.available;
          // If availableCredit is an array, then log its first element
          if (Array.isArray(availableCredit)) {
            console.log(availableCredit[0]);
          }

          weekNumber = parseInt(weekNumber, 10);
          var nextWeek = weekNumber + 1;

          // Ensure both weekNumber and availableCredit are numbers before making this comparison

          if (
            availableCredit[0] == weekNumber.toString() ||
            nextWeek.toString()
          ) {
            console.log("CREDIT USED");
            params.credit = availableCredit[0];
          }
        }
        // alert('KMT');
        if (method == "add" || method == "shift") {
          console.log("ADD");
          //params.keepDuo = true;

          params.keepDuo = this.keepDuo;
          params.extra = this.extra;
          // if((authStore.isAdmin || authStore.userLevel == 2 || authStore.userLevel == 3) && eventInfo.event.extendedProps.status !== 2 && membership == 'duo' && method == 'shift'){
          //   if(this.keepDuo){
          //     params.keepDuo = false;
          //   }

          // }
          try {
            this.isLoading = true;
            console.log("Voeg toe: " + JSON.stringify(params));
            //params.keepDuo = true;
            const response = await axios.post(
              import.meta.env.VITE_API_URL + "update-timeslot.php",
              JSON.stringify(params)
            );
            console.log("add timeslot");
            console.log("eventInfo: " + JSON.stringify(eventInfo.event));
            console.log("response " + JSON.stringify(response.data));

            if (response.data.error) {
              this.error = response.data.error;
            } else {
              this.error = null;
              if (!authStore.isAdmin || authStore.user.usertype != "support") {
                // Check if the response contains a recurring property
                let newEvents = response.data.timeslots
                  ? response.data.timeslots
                  : [response.data];
                newEvents.forEach((newEvent) => {
                  // Find the matching week object in combinedWeeksAndTrainings
                  let weekNumber = newEvent.week;
                  let matchingWeek = authStore.combinedWeeksAndTrainings.find(
                    (weekObject) => weekObject.week == weekNumber
                  );
                  // Update the training property with the new event
                  if (matchingWeek) {
                    console.log("MATCH!!! ", matchingWeek);
                    matchingWeek.training = newEvent;
                  }
                  if(memberStore.selectedMember) {
                    // Refetch the selected member's timeslots to make sure the new event is included
                    // Should be added without refetching the entire user's timeslots
                    memberStore.getSelectedMemberTimeslots();
                  }
                  // Rules for removing a credit from to the user
                  authStore.user.bookings++;
                  // Credit decrease should happen if booked a credit
                  // authStore.user.credits--;
                });
              }
              this.recurringFixed = false;
              this.useCredit = false;
            }
            return response.data;
          } catch (error) {
            this.error = error.message;
            if (error.response?.status === 401) {
              // Specifieke afhandeling voor 401 Unauthorized
              console.error("Authentication error: Unauthorized (401)");
              authStore.logout();
            }
          } finally {
            this.loading = false;
            modalStore.setModalVisible("selectEvent", false);
          }
        }
        if (method == "remove" && confirm("Boeking verwijderen?")) {
          var params = {
            job: method,
            location: member.location_id,
            start: eventInfo.event.start,
            end: eventInfo.event.end,
            user: member.id,
            // state : state,
            users: eventMembers,
            token: authStore.user.token,
            // status : eventStatus,
          };
          if (this.forceRemove) {
            params.force_remove = true;
            //confirm('Forceer verwijderen?');
          }
          if (
            (authStore.isAdmin ||
              authStore.userLevel == 2 ||
              authStore.userLevel == 3) &&
            eventInfo.event.extendedProps.status !== 2 &&
            member.usertype == "duo" &&
            !confirm("Duo partner ook afzeggen?")
          ) {
            params.keepDuo = false;
          } else if (member.usertype == "duo") {
            params.keepDuo = true;
          }
          try {
            this.isLoading = true;
            console.log("sending params-->" + JSON.stringify(params));
            const response = await axios.post(
              import.meta.env.VITE_API_URL + "update-timeslot.php",
              JSON.stringify(params)
            );
            console.log("remove timeslot");
            console.log(response.data);

            authStore.user.bookings--;

            const date = response.data.date;
            const time = response.data.start;
            const dbID = response.data.dbID;
            const datetime = new Date(`${date}T${time}:00`);

            // if(!authStore.isAdmin || authStore.user.usertype != 'support') {
            //   var timeslots = authStore.user.timeslots;
            // } else {
            //   var timeslots = memberStore.selectedMember.timeslots;
            // }
            var timeslots = member.timeslots;

            const matchingEvent = timeslots.find((event) => {
              const eventStart = new Date(event.start);
              return eventStart.getTime() === datetime.getTime();
            });
            if (matchingEvent) {
              // Update the matching event here
              console.log("match ---> " + JSON.stringify(matchingEvent));
              const matchingIndex = timeslots.findIndex((item) => {
                const isMatch = item.extendedProps.dbID == dbID;
                if (isMatch) {
                  console.log("Match found:", item.extendedProps.dbID);
                } else {
                  console.log("No match:", item.extendedProps.dbID);
                }
                return isMatch;
              });

              // if (matchingIndex >= 0) {
              //   console.log('!!!!!' + Date.now());
              //   // This works but doesn't sync with others like bookableWeeks
              //   if(!authStore.isAdmin || authStore.user.usertype != 'support') {
              //     authStore.user.timeslots = authStore.user.timeslots.filter((_, index) => index !== matchingIndex);
              //     authStore.lastUpdate = Date.now();  // update lastUpdate
              //   } else {
              //     memberStore.selectedMember.timeslots = memberStore.selectedMember.timeslots.filter((_, index) => index !== matchingIndex);
              //     memberStore.lastUpdate = Date.now();
              //   }
              // }
              if (matchingIndex >= 0) {
                console.log("!!!!!" + Date.now());
                member.timeslots = member.timeslots.filter(
                  (_, index) => index !== matchingIndex
                );
                authStore.lastUpdate = Date.now(); // update lastUpdate
              }
            }

            // For admin app when admin remove timeslot from selectedMember - Remove the timeslot there
            // if(memberStore.selectedMember.timeslots > 0) {
            //   const selectedMemberTimeslots = memberStore.selectedMember.timeslots;
            //   const matchingIndexSelected = selectedMemberTimeslots.findIndex(item => {
            //     const isSelectedMatch = item.extendedProps.dbID === dbID;

            //     return isSelectedMatch;
            //   });
            //   if (matchingIndexSelected >= 0) {
            //     selectedMemberTimeslots.splice(matchingIndexSelected, 1);
            //   }
            // }

            // eventInfo.el.style.backgroundColor = "#000FF0";
            eventInfo.event.setExtendedProp("status", response.data.state);
            eventInfo.event.setExtendedProp("members", response.data.members);
            eventInfo.event.setExtendedProp("dbID", response.data.event_id);

            // Rules for adding a credit back to the user

            this.error = null;

            return response.data;
          } catch (error) {
            this.error = error.message;
            if (error.response?.status === 401) {
              // Specifieke afhandeling voor 401 Unauthorized
              console.error("Authentication error: Unauthorized (401)");
              authStore.logout();
            }
          } finally {
            this.loading = false;

            modalStore.setModalVisible("removeEvent", false);
          }
        }
        console.log("method: " + method);
      }
    },
    async unbookAllEvents() {
      if (confirm("Alle gemaakte afspraken afzeggen?")) {
        const authStore = useAuthStore();
        const member = authStore.user;
        const events = authStore.sortedTimeslots.upcoming;

        const currentTime = new Date().getTime();

        const validEvents = events.filter((event) => {
          const eventTime = new Date(event.start).getTime();
          // Check if the event is at least 1 hours past the current timestamp
          return eventTime - currentTime >= 1 * 60 * 60 * 1000; // 1 hours in milliseconds
        });
        const dbArray = validEvents.map((event) => event.extendedProps.dbID);

        // Convert the array to a string representation
        const dbString = JSON.stringify(dbArray);

        var params = {
          job: "remove",
          location: member.location_id,
          user: member.id,
          db: dbString,
          token: member.token,
        };

        try {
          this.isLoading = true;
          console.log("sending params-->" + JSON.stringify(params));
          const response = await axios.post(
            import.meta.env.VITE_API_URL + "update-timeslot.php",
            JSON.stringify(params)
          );
          console.log("remove all upcoming timeslots");
          console.log("Reponse:   " + JSON.stringify(response.data, null, 2));
          this.error = null;
          return response.data;
        } catch (error) {
          this.error = error.message;
          if (error.response?.status === 401) {
            // Specifieke afhandeling voor 401 Unauthorized
            console.error("Authentication error: Unauthorized (401)");
            authStore.logout();
          }
        } finally {
          this.loading = false;
          // modalStore.setModalVisible('removeEvent', false)
        }
        // const response = await axios.post(import.meta.env.VITE_API_URL+'update-timeslot.php', JSON.stringify(events));
      }
    },
  },
  persist: true,
});
