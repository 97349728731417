<script setup>
import Modal from '@/components/Modal.vue'; // Import the modal component

import { ref, watch, computed, onMounted } from 'vue'
import { useRouter, RouterLink, RouterView } from 'vue-router'
import 'add-to-calendar-button'
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';
import { capitalizeFirstLetter } from '../helpers/functions'

import moment from 'moment'
import 'moment/dist/locale/nl'

import { useAuthStore } from '../store/AuthStore';
import { useLocationStore } from '../store/LocationStore'
import { useMemberStore } from '../store/MemberStore'
import { useEventStore } from '../store/EventStore'
import { useModalStore } from '../store/ModalStore'

const authStore = useAuthStore();
const memberStore = useMemberStore()
const eventStore = useEventStore();
const locationStore = useLocationStore()
const modalStore = useModalStore()
const router = useRouter();

onMounted(() => {
  if (authStore.user && authStore.user.usertype === 'support') {
    router.push('/location/1/calendar');
  }
});

import { useRoute } from 'vue-router';
const route = useRoute();

let currentTab = ref('Tab1')

function handleMemberSelectionChange(event) {
  const memberId = event.target.value;
  memberStore.getMemberById(memberId);
}

function handleLocationSelectionChange(event) {
  const locationId = event.target.value;
  locationStore.setCurrentLocation(locationId);
}

function resetSelectedMember() {
  memberStore.selectedMember = {};
}

function removeTimeslotFromMember(clickInfo) {
  // console.log(clickInfo);
  var eventMembers = clickInfo.extendedProps.users;
  const memberStore = useMemberStore()

  var userId = memberStore.selectedMember.id;
  if (eventMembers.includes(userId)) {
    const eventStore = useEventStore()
    eventStore.removeEvent(clickInfo);
  } else {
    const eventStore = useEventStore()
    eventStore.selectEvent(clickInfo);
  }
}

function removeEvent() {
  const eventStore = useEventStore()
  eventStore.updateEvent().then((result) => {
    console.log('removeEvent finished with result:', result);
  }).catch((error) => {
    console.log('removeEvent failed with error:', error);
    // Handle the error here
  });
}

watch(() => locationStore.selectedLocation, async (newVal, oldVal) => {
  // console.log('new ' + newVal, 'old ' + oldVal)
  if (newVal && Object.keys(newVal).length > 0 && newVal !== oldVal) {
    memberStore.selectedMember = {};
    await locationStore.fetchLocationMembers();
  }
});

 // Whenever the search value changes, try to find a member that matches the search
watch(
  () => memberStore.search,
  (newVal) => {
    const matchingMember = locationStore.filteredMembers.find(member => 
      memberStore.fullName(member).toLowerCase().includes(newVal.toLowerCase())
    );
    if (matchingMember) {
      memberStore.selectedMember = matchingMember;
    }
  }
);
</script>

<template>
  <!-- <div>
    <div class="px-8 header">
      <h3 class="mb-3">Locatie selecteren</h3>
      <select class="form-select" id="selectedLocation" v-model="locationStore.selectedLocation.id" @change="handleLocationSelectionChange($event)">
        <option value="" disabled>Kies een locatie</option>
        <option v-for="(location, index) in locationStore.locations" :key="index" :value="location.id">
          {{ location.name }}
        </option>
      </select>
      <router-link
        :to="`/calendar`"
        v-slot="{ href, navigate }"
        custom
      >
          <a :href="href" @click="resetSelectedMember(), navigate">
            <button class="btn btn-green btn-lg w-100 my-3">Kalender bekijken</button>
          </a>
      </router-link>
    </div>
    <div class="px-8 my-4" v-if="locationStore.selectedLocation && Object.keys(locationStore.selectedLocation).length > 0">
      <h3>Lid selecteren</h3>
      <input class="form-control mb-2" type="search" v-model="memberStore.search" placeholder="Zoek leden..">
      <select class="form-select" id="selectedMember" v-model="memberStore.selectedMember.id" @change="handleMemberSelectionChange($event)">
        <option :value="null">Kies een lid</option>
        <option v-for="(member, index) in locationStore.filteredMembers" :key="index" :value="member.id">
          {{ memberStore.fullName(member) }}
        </option>
      </select>
    </div>
    <div v-if="memberStore.selectedMember && Object.keys(memberStore.selectedMember).length > 0">
      <h3>Acties</h3>
      <router-link
        :to="`/calendar`"
        v-slot="{ href, navigate }"
        custom
      >
          <a :href="href" @click="navigate">
            <button class="btn btn-green btn-lg w-100 my-3">Kalender bekijken</button>
          </a>
      </router-link>

      <button @click="memberStore.selectMember(memberStore.selectedMember)" class="btn btn-green btn-lg w-100">Profiel bekijken</button><br>
    </div>
  </div> -->
  <Modal :id="'selectMember'" :title="memberStore.fullName(memberStore.selectedMember)" v-if="modalStore.isModalVisible('selectMember')" storeName="memberStore" functionName="deselectMember">
      <div>
         <!-- Navigation for tabs -->
        <div class="row">
          <div class="col-12">
            <ul class="nav nav-tabs mb-3">
              <li class="nav-item">
                <a class="nav-link" :class="{ active: currentTab === 'Tab1' }" @click="currentTab = 'Tab1'">Gegevens</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{ active: currentTab === 'Tab2' }" @click="currentTab = 'Tab2'">Trainingen</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{ active: currentTab === 'Tab3' }" @click="currentTab = 'Tab3'">Geschiedenis</a>
              </li>
            </ul>
          </div>
        </div>
        <div v-show="currentTab == 'Tab1'" class="row g-3">
          <div class="col-12">
            <span class="badge bg-secondary">ID: {{memberStore.selectedMember.id}}</span>
          </div>
          <div class="col-md-6">
            <div class="form-floating">
              <input v-model="memberStore.selectedMember.firstname" type="text" class="form-control" id="memberFirstname" placeholder="John">
              <label for="memberFirstname">Voornaam</label>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-floating">
              <input v-model="memberStore.selectedMember.lastname" type="text" class="form-control" id="memberLastname" placeholder="Doe">
              <label for="memberLastname">Achternaam</label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating">
              <input v-model="memberStore.selectedMember.email" type="text" class="form-control" id="memberEmail" placeholder="example@example.com">
              <label for="memberEmail">E-mailadres</label>
            </div>
          </div>
          <div class="col-12">
            <div class="form-floating">
              <input v-model="memberStore.selectedMember.phone" type="text" class="form-control" id="memberPhone" placeholder="07012341234">
              <label for="memberPhone">Telefoonnummer</label>
            </div>
          </div>
          <div class="col-12 mt-4">
            <h5 class="fw-bold">Abonnement</h5>
            <select class="form-select" id="selectedMembershipMemberships" v-model="memberStore.selectedMember.usertype">
              <option v-for="(membership, index) in memberStore.memberships" :key="index" :value="membership">
                {{ capitalizeFirstLetter(membership) }}
              </option>
            </select>
          </div>
          <div class="col-12 mt-4">
            <h5 class="fw-bold">Notificaties</h5>
            <div class="form-check">
              <input v-model="memberStore.selectedMember.notify" type="checkbox" class="form-check-input" id="memberNotify" disabled>
              <label class="form-check-label" for="memberNotify">Heeft e-mail notificaties aan?</label>
            </div>
          </div>
        </div>
        <div v-show="currentTab == 'Tab2'" class="row g-3">
          <div class="training-overview">
            <div class="training" v-for="(timeslot, index) in memberStore.filteredMemberTimeslotsComing" :key="index">
              <span class="date" @click="handleEventClick(timeslot)">{{ capitalizeFirstLetter(moment(timeslot.start).format('ddd D MMMM YYYY')) }} - {{  moment(timeslot.start).format('LT') }} - {{ moment(timeslot.end).format('LT') }}</span>
              <span class="actions">
                <button @click="removeTimeslotFromMember(timeslot)" class="btn btn-red"><font-awesome-icon icon="far fa-times"/></button>
              </span>
            </div>
          </div>
        </div>
        <div v-show="currentTab == 'Tab3'" class="row g-3">
          <div class="training-overview">
            <div class="training mb-2" v-for="(timeslot, index) in memberStore.filteredMemberTimeslotsPast" :key="index">
              <span class="date">{{ capitalizeFirstLetter(moment(timeslot.start).format('ddd D MMMM YYYY')) }} - {{  moment(timeslot.start).format('LT') }} - {{ moment(timeslot.end).format('LT') }}</span>
              <!-- <span class="actions">
                <button @click="removeTimeslotFromMember(timeslot)" class="btn btn-red"><font-awesome-icon icon="far fa-times"/></button>
              </span> -->
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <button :disabled="!memberStore.isSelectedMemberChanged" class="btn btn-primary" @click="memberStore.updateMember(memberStore.selectedMember)">Opslaan</button>
        </div>
      </div>
    </Modal>
    <Modal :id="'removeEvent'" title="" v-if="modalStore.isModalVisible('removeEvent')">
      <div>
        <div class="row g-3">
          <div class="col">
            <span class="time h4 d-block">{{ capitalizeFirstLetter(moment(eventStore.eventInfo.event.start).format('dddd, D MMMM, YYYY')) }}</span>
            <span class="timeslot d-block">
              Tijdslot: {{  moment(eventStore.eventInfo.event.start).format('LT') }} - {{ moment(eventStore.eventInfo.event.end).format('LT') }}
            </span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="button-group">
            <button type="button" class="btn btn-red-outline me-2" @click="removeEvent">Afzeggen</button>
          </div>
        </div>
      </div>
  </Modal>
</template>
